import {Injectable} from '@angular/core';
import {QueryBuilder} from 'src/app/shared/util/query-builder';
import {Observable, of} from 'rxjs';
import {OsmHttpClientService} from '@sesame/sesame-fe-library';
import {catchError} from "rxjs/operators";
import {UpdateUserDetails, UserDetails} from "src/app/shared/models/user";

export interface LinkedUserResponse {
  role: string;
  user: User
}

export interface User {
  firstName: string;
  lastName: string;
  email: string;
}

@Injectable({providedIn: 'root'})
export class UsersCommand {

  constructor(
    private readonly osmHttpClientService: OsmHttpClientService,
  ) {
  }

  getMyUserDetails(): Observable<UserDetails> {
    const reqUrl = QueryBuilder.forPath('my/user')
      .build();
    return this.osmHttpClientService.get<UserDetails>(reqUrl);
  }

  updateMyLanguage(language: string): Observable<void> {
    const reqUrl = QueryBuilder.forPath('my/user')
      .build();
    return this.osmHttpClientService.patch<void, UpdateUserDetails>(reqUrl, {language} as UpdateUserDetails);
  }

  link(ciamId: string, careerId: string): Observable<void> {
    const query = QueryBuilder.forPath('users/{ciamId}/link')
      .withPathVariable('ciamId', ciamId)
      .build();
    return this.osmHttpClientService.put(query, {careerId: careerId});
  }

  getUserLinkedToCareer(ciamId: string, careerId: string): Observable<LinkedUserResponse> {
    const query = QueryBuilder.forPath('users/{ciamId}/career/{careerId}/link')
      .withPathVariable('ciamId', ciamId)
      .withPathVariable('careerId', careerId)
      .build();

    return this.osmHttpClientService.get<LinkedUserResponse>(query)
      .pipe(
        catchError(httpError => {
          if (httpError.error.status === 404) {
            //this means there is no linked user for given career
            return of(null);
          } else {
            throw httpError;
          }
        })
      );
  }
}
