import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, switchMap} from 'rxjs';
import {Injectable} from '@angular/core';
import {filter, take} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {LibOrganisationContextService, MyOrganisation} from "@sesame/sesame-fe-library";

@Injectable()
export class OSSHeadersRequestInterceptor implements HttpInterceptor {
  private organisationId = '';

  constructor(private readonly organisationContextService: LibOrganisationContextService) {
    this.organisationContextService.getCurrent()
      .pipe(filter(organisation => organisation != null))
      .subscribe(organisation => {
        this.organisationId = organisation.organisationId;
      });
  }

  private static shouldAddAppliesToOrganisationIdHeader(req: HttpRequest<any>): boolean {
    return req.url.startsWith(environment.osm.baseUrl)
      || req.url.startsWith(environment.lms.baseUrl)
      || req.url.startsWith(environment.ess.baseUrl);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (OSSHeadersRequestInterceptor.shouldAddAppliesToOrganisationIdHeader(req)) {
      if (req.headers.has('X-Applies-To-OrganisationId')) {
        return next.handle(req);
      }
      return this.organisationContextService.getCurrent()
        .pipe(
          take(1),
          switchMap(organisation => this.handleRequestWithOrganisationIdIfPresent(req, next, organisation))
        );
    }
    return next.handle(req);
  }

  private handleRequestWithOrganisationIdIfPresent(
    req: HttpRequest<any>,
    next: HttpHandler,
    organisation: MyOrganisation
  ): Observable<HttpEvent<any>> {
    if (organisation) {
      const httpRequest = req.clone({
        headers: req.headers.set('X-Applies-To-OrganisationId', organisation.organisationId)
      });
      return next.handle(httpRequest);
    } else {
      return next.handle(req)
    }
  }
}
