<form [formGroup]="dataMasterSystemForm">
  <mat-form-field class="w-100 notranslate">
    <mat-label>{{'organisation.user.configure-platform-user-dialog.step-1.select-label' | translate}}</mat-label>
    <mat-select formControlName="dataMasterSystem">
      <mat-option *ngFor="let currentDataMasterSystem of ALL_DATA_MASTER_SYSTEMS" [value]="currentDataMasterSystem">
        {{ 'data-master-system.' + currentDataMasterSystem | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <app-message-box *ngIf="dataMasterSystemForm.get('dataMasterSystem').value === 'wisa'">
    {{'organisation.user.configure-platform-user-dialog.step-1.wisa-message-box' | translate}}
  </app-message-box>
</form>