<div class="d-flex justify-content-start gap-2">
  <label class="">{{'organisation.integration.client-id' | translate}}:</label>
  <span>{{organisationClient.clientId}}</span>
</div>
<div class="d-flex justify-content-start gap-2">
  <label>{{'organisation.integration.client-secret' | translate}}:</label>
  <a *ngIf="!isClientSecretVisible" class="show-text-link"
     (click)="toggleClientSecretVisibility()">
    {{'organisation.integration.show-client-secret' | translate}}
  </a>
  <ng-container *ngIf="isClientSecretVisible">
    <span>{{organisationClient.clientSecret}}</span>
    <a class="show-text-link" (click)="toggleClientSecretVisibility()">
      {{'organisation.integration.hide-client-secret' | translate}}
    </a>
  </ng-container>
</div>
<div class="d-flex justify-content-start gap-2">
  <label>{{'organisation.integration.web-access-url' | translate}}:</label>
  <span>{{organisationClient.accessUrl}}</span>
</div>
<div class="d-flex justify-content-start gap-2">
  <label>{{'organisation.integration.token-endpoint' | translate}}:</label>
  <span>{{organisationClient.tokenUrl}}</span>
</div>