import {ChangeDetectorRef, Component, Injector, Input, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {AppVariables} from 'src/app/shared/util/app-variables';
import {LibBaseComponent, LibOrganisationContextService, MediaMatcherService} from '@sesame/sesame-fe-library';
import {OnAfterCreate} from 'src/app/shared/util/on-after-create';
import {NavigationItem} from 'src/app/shared/models/navigation-item';
import {AuthService} from '@auth0/auth0-angular';
import {environment} from "src/environments/environment";
import {of, switchMap} from "rxjs";
import {OrganisationQuery} from "src/app/shared/service/organisation-query";

@Component({
  selector: 'app-main-sidevan-panel',
  templateUrl: './main-sidevan-panel.component.html',
  styleUrls: ['./main-sidevan-panel.component.scss']
})
export class MainSidevanPanelComponent extends LibBaseComponent implements OnAfterCreate {
  readonly mobileQuery: MediaQueryList;

  readonly _mobileQueryListener: () => void;
  @Input()
  readonly menuItems: NavigationItem[];

  @ViewChild(MatSidenav)
  readonly sidenav: MatSidenav;

  public readonly defaultToggleStatus = AppVariables.sidenav.default.toggleStatus;
  initialized = false;
  isAuthenticated = false;
  isPlatformUser = true;

  constructor(changeDetectorRef: ChangeDetectorRef,
              mediaMatcher: MediaMatcherService,
              authService: AuthService,
              private readonly injector: Injector,
              private readonly organisationQuery: OrganisationQuery
  ) {
    super();
    authService.isAuthenticated$
      .pipe(this.takeUntilDestroy())
      .subscribe(isAuthenticated$ => this.isAuthenticated = isAuthenticated$);
    this.mobileQuery = mediaMatcher.matchMedia();
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    if (!environment.isCustomerServiceContext) {
      const organisationContextService = this.injector.get(LibOrganisationContextService);
      organisationContextService.getCurrent()
        .pipe(
          this.takeUntilDestroy(),
          switchMap(myOrganisation => myOrganisation ? this.organisationQuery.findOne(myOrganisation.organisationId) : of(null))
        )
        .subscribe(organisation => this.isPlatformUser = organisation?.isPlatformUser);
    }
  }

  toggle(isOpen?: boolean): void {
    this.sidenav?.toggle(isOpen);
  }

  onAfterCreate(): void {
    const setSidenavStatus = () => {
      this.sidenav?.toggle(
        this.mobileQuery.matches ?
          this.defaultToggleStatus.mobile :
          this.defaultToggleStatus.desktop
      );
    };
    setTimeout(() => {
      setSidenavStatus();
      this.initialized = true;
    }, 200);
    this.mobileQuery.addEventListener('change', () => setSidenavStatus());
  }
}
