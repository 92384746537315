import {Injectable} from '@angular/core';

export enum StorageDataType {
  GROUPS = 'GROUPS',
  LICENSES = 'LICENSES',
  USERS = 'USERS',
  ORGANISATIONS = 'ORGANISATIONS',
  ACTIVATION_CODES = 'ACTIVATION_CODES',
  AUTO_DELIVERY = 'AUTO_DELIVERY'
}

export interface StorageData<RAW_DATA, FILTER_DATA> {
  values: RAW_DATA[];
  pageSize: number;
  lastIndex: number;
  filter: FILTER_DATA;
}

@Injectable({providedIn: 'root'})
export class TableDataStorageService<RAW_DATA, FILTER_DATA> {
  private static readonly EMPTY = {
    values: [],
    pageSize: null,
    lastIndex: null,
    filter: null
  };

  constructor() {
    this.init();
  }

  public static clearData(): void {
    Object.keys(StorageDataType).forEach(value => localStorage.removeItem(value));
  }

  public add(type: StorageDataType, result: StorageData<RAW_DATA, FILTER_DATA>): void {
    this.save(type, {
      values: result.values,
      pageSize: result.pageSize,
      lastIndex: result.lastIndex,
      filter: result.filter
    });
  }

  public get(type: StorageDataType): StorageData<RAW_DATA, FILTER_DATA> {
    const item = localStorage.getItem(type);
    if (!item) {
      return TableDataStorageService.EMPTY;
    }
    return JSON.parse(item);
  }

  private init(): void {
    Object.keys(StorageDataType)
      .forEach(value => localStorage.setItem(
        value,
        JSON.stringify(TableDataStorageService.EMPTY as StorageData<RAW_DATA, FILTER_DATA>))
      );
  }

  private save(type: StorageDataType, storageData: StorageData<RAW_DATA, FILTER_DATA>): void {
    localStorage.removeItem(type);
    localStorage.setItem(type, JSON.stringify(storageData));
  }

}
