import {Component, Input} from '@angular/core';
import {LibBaseComponent} from '@sesame/sesame-fe-library';
import {IconName} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent extends LibBaseComponent {
  @Input()
  readonly icon: IconName;
  @Input()
  readonly label: string;
  @Input()
  readonly id: string;

  constructor() {
    super();
  }
}
