import {Organisation} from "src/app/shared/models/organisation";
import {Component, Inject, OnInit} from "@angular/core";
import {DialogBaseComponent, DialogService, InlineMessageStyle, SnackBarService} from "@sesame/sesame-fe-library";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {OperationalOrganisationService} from "src/app/shared/service/operational-organisation.service";
import {catchError} from "rxjs/operators";
import {EMPTY} from "rxjs";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-delete-data-school-data-master-system-dialog',
  templateUrl: './delete-data-school-data-master-system-dialog.component.html',
  styleUrls: ['./delete-data-school-data-master-system-dialog.component.scss']
})
export class DeleteDataSchoolDataMasterSystemDialogComponent extends DialogBaseComponent<DeleteDataSchoolDataMasterSystemDialogData, void> implements OnInit {
  protected readonly InlineMessageStyle = InlineMessageStyle;
  deleteDataButtonLoading: boolean = false;

  constructor(
    dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) data: DeleteDataSchoolDataMasterSystemDialogData,
    private readonly operationalOrganisationService: OperationalOrganisationService,
    private snackbarService: SnackBarService,
    private readonly router: Router,
    private readonly translate: TranslateService,
  ) {
    super(dialogService, data);
  }

  ngOnInit(): void {
    this.subscribeOnDeleteDataButtonLoading();
  }

  onDeleteData() {
    this.setSaveButtonLoadingStatus(true);
    this.operationalOrganisationService.deleteDataSchoolManagementSystem(this.payload.organisation.organisationId)
      .pipe(
        this.takeOne(),
        catchError(() => {
          this.setSaveButtonLoadingStatus(false);
          this.snackbarService.showError(
            this.translate.instant('organisation.user.delete-organisation-data-dialog.error'));
          return EMPTY;
        }))
      .subscribe(() => {
        this.setSaveButtonLoadingStatus(false);
        this.snackbarService.showSuccess(this.translate.instant('organisation.user.delete-organisation-data-dialog.success'));
        this.submit();
      })
  }

  getResult(): void {
  }

  private subscribeOnDeleteDataButtonLoading(): void {
    this.isSaveButtonLoading()
      .pipe(
        this.takeUntilDestroy()
      )
      .subscribe((value: boolean) => {
        this.deleteDataButtonLoading = value;
      });
  }

}


export interface DeleteDataSchoolDataMasterSystemDialogData {
  organisation: Organisation
}