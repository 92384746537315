import {NgModule, Provider} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {LoginPageComponent} from './core/pages/login-page/login-page.component';
import {SecuredContentPageComponent} from 'src/app/core/pages/secured-content-page/secured-content-page.component';
import {NotFoundPageComponent} from './core/pages/not-found-page/not-found-page.component';
import {HomePageComponent} from 'src/app/core/pages/home-page/home-page.component';
import {EmptyPageComponent} from 'src/app/core/pages/empty-page/empty-page.component';
import {CanActivateRouteGuard} from 'src/app/core/security/can-activate-route.guard';
import {PrivilegeType} from 'src/app/shared/models/privilege-type';
import {environment} from 'src/environments/environment';
import {OrganisationSupportContentPageComponent} from 'src/app/core/pages/organisation-support-content-page/organisation-support-content-page.component';
import {FeatureGuard} from 'src/app/core/features/feature.guard';
import {AppVariables} from 'src/app/shared/util/app-variables';
import {OSSHeadersRequestInterceptor} from 'src/app/core/interceptors/oss-headers-request-intercepter.service';
import {IsPlatformUserGuard} from "src/app/core/security/is-platform-user-guard";
import {SpinnerInterceptor} from '@sesame/sesame-fe-library';
import {SmsDataImportComponent} from "src/app/features/sms-data-import/components/sms-data-import/sms-data-import.component";
import {AppPageComponent} from "src/app/core/pages/app-page/app-page.component";
import {AuthGuard} from "src/app/core/security/auth-guard.service";
import {featuresResolver} from "src/app/shared/service/features.resolver";
import {ResultPageComponent} from "src/app/core/pages/result-page/result-page.component";

function getRequiredPrivilege(path: string): PrivilegeType[] {
  const navItem = AppVariables.navigationItems[environment.entryPoint]
    ?.filter(navigationItem => {
      return navigationItem.path.includes(path);
    })[0];
  return navItem?.privilegeTypes;
}

const customerServiceRoutes: Routes = [
  {
    path: '',
    component: SecuredContentPageComponent,
    data: {entryPoint: environment.entryPoint},
    children: [
      {path: 'empty', component: EmptyPageComponent},
      {
        path: '', pathMatch: 'full', component: HomePageComponent
      },
      {
        path: 'app', pathMatch: 'full', component: AppPageComponent, canActivate: [AuthGuard]
      },
      {
        path: 'products',
        loadChildren: () => import('./features/products/products.module').then(m => m.ProductsModule),
        canActivate: [AuthGuard, CanActivateRouteGuard],
        data: {requiredPrivileges: getRequiredPrivilege('products')},
        resolve: {features: featuresResolver}
      },
      {
        path: 'licenses',
        loadChildren: () => import('./features/licenses/licenses.module').then(m => m.LicensesModule),
        canActivate: [AuthGuard, CanActivateRouteGuard],
        data: {requiredPrivileges: getRequiredPrivilege('licenses')},
        resolve: {features: featuresResolver}
      },
      {
        path: 'activation-codes',
        loadChildren: () => import('./features/activation-codes/activation-codes.module').then(m => m.ActivationCodesModule),
        canActivate: [AuthGuard, CanActivateRouteGuard],
        data: {requiredPrivileges: getRequiredPrivilege('activation-codes')},
        resolve: {features: featuresResolver}
      },
      {
        path: 'organisations',
        loadChildren: () => import('./features/organisations/organisations.module').then(m => m.OrganisationsModule),
        canActivate: [AuthGuard, CanActivateRouteGuard],
        data: {requiredPrivileges: getRequiredPrivilege('organisations')},
        resolve: {features: featuresResolver}
      },
      {
        path: 'users',
        loadChildren: () => import('./features/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard, CanActivateRouteGuard, IsPlatformUserGuard],
        data: {requiredPrivileges: getRequiredPrivilege('users')},
        resolve: {features: featuresResolver}
      },
      {
        path: 'auto-delivery',
        loadChildren: () => import('./features/auto-delivery/auto-delivery.module').then(m => m.AutoDeliveryModule),
        canActivate: [AuthGuard, CanActivateRouteGuard],
        data: {requiredPrivileges: getRequiredPrivilege('auto-delivery')},
        resolve: {features: featuresResolver}
      },
      {
        path: 'groups',
        loadChildren: () => import('./features/groups/groups.module').then(m => m.GroupsModule),
        canLoad: [FeatureGuard],
        canActivate: [AuthGuard, CanActivateRouteGuard, IsPlatformUserGuard],
        data: {
          requiredPrivileges: getRequiredPrivilege('group'),
          requiredFeature: 'groupsModule'
        },
        resolve: {features: featuresResolver}
      }
    ]
  }, {
    path: 'login',
    component: LoginPageComponent
  },
  {path: 'result', component: ResultPageComponent},
];

const organisationSupportServiceRoutes: Routes = [
  {
    path: '',
    component: OrganisationSupportContentPageComponent,
    data: {entryPoint: environment.entryPoint},
    children: [
      {
        path: '', pathMatch: 'full', component: HomePageComponent
      },
      {
        path: 'app', pathMatch: 'full', component: AppPageComponent, canActivate: [AuthGuard]
      },
      {path: 'empty', component: EmptyPageComponent},
      {
        path: 'groups',
        loadChildren: () => import('./features/groups/groups.module').then(m => m.GroupsModule),
        canLoad: [FeatureGuard],
        canActivate: [AuthGuard, CanActivateRouteGuard, IsPlatformUserGuard],
        data: {
          requiredPrivileges: getRequiredPrivilege('group'),
          requiredFeature: 'groupsModule'
        },
        resolve: {features: featuresResolver}
      },
      {
        path: 'licenses',
        loadChildren: () => import('./features/licenses/licenses.module').then(m => m.LicensesModule),
        canActivate: [AuthGuard, CanActivateRouteGuard],
        data: {requiredPrivileges: getRequiredPrivilege('licenses')},
        resolve: {features: featuresResolver}
      },
      {
        path: 'users',
        loadChildren: () => import('./features/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard, CanActivateRouteGuard, IsPlatformUserGuard],
        data: {requiredPrivileges: getRequiredPrivilege('users')},
        resolve: {features: featuresResolver}
      },
      {
        path: 'account-settings',
        loadChildren: () => import('./features/account-settings/account-settings.module').then(m => m.AccountSettingsModule),
        canActivate: [AuthGuard],
        resolve: {features: featuresResolver}
      },
      {
        path: 'organisations/:id/sms-data-import',
        component: SmsDataImportComponent,
        canActivate: [AuthGuard],
        resolve: {features: featuresResolver}
      }
    ]
  },
  {path: 'result', component: ResultPageComponent},
  {path: 'not-found', component: NotFoundPageComponent},
  {
    path: '**',
    redirectTo: 'not-found'
  }
];

function contextProviders(): Provider[] {
  const spinnerInterceptor = {
    provide: HTTP_INTERCEPTORS,
    useClass: SpinnerInterceptor,
    multi: true
  };
  const ossHeadersInterceptor = {
    provide: HTTP_INTERCEPTORS,
    useClass: OSSHeadersRequestInterceptor,
    multi: true
  };
  return environment.isCustomerServiceContext === true ? [spinnerInterceptor] : [spinnerInterceptor, ossHeadersInterceptor];
}

function routes(): Routes {
  return environment.isCustomerServiceContext === true ? customerServiceRoutes : organisationSupportServiceRoutes;
}

@NgModule({
  imports: [RouterModule.forRoot(routes())],
  exports: [RouterModule],
  providers: contextProviders()
})
export class AppRoutingModule {
}
