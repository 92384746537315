import {Component} from '@angular/core';
import {LibBaseComponent} from '@sesame/sesame-fe-library';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent extends LibBaseComponent {

  constructor() {
    super();
  }
}
