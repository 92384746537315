import {Component, OnInit} from '@angular/core';
import {environment} from '../environments/environment';
import {datadogRum} from '@datadog/browser-rum';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {LibBaseComponent} from '@sesame/sesame-fe-library';
import {DUTCH, ENGLISH, FRENCH, SWEDISH, LibLanguageService} from '@sesame/sesame-fe-library';
import {AngularFaviconService} from "angular-favicon";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends LibBaseComponent implements OnInit {
  title = 'customer-support-portal';
  entryPoint = environment.entryPoint;

  constructor(private readonly titleService: Title,
              private readonly translate: TranslateService,
              private readonly languageService: LibLanguageService,
              private ngxFavicon: AngularFaviconService
  ) {
    super();
    this.ngxFavicon.setFavicon(environment.logo.favIcon);
    translate.addLangs([ENGLISH.code, FRENCH.code, DUTCH.code, SWEDISH.code]);
    translate.setDefaultLang(environment.defaultLang.code);

    this.languageService.getCurrent()
      .pipe(this.takeUntilDestroy())
      .subscribe(currentLanguage => {
        translate.use(currentLanguage.code);
      });

    translate.get(environment.app.name, {tenant: environment.tenantName})
      .pipe(this.takeUntilDestroy())
      .subscribe(appTitleTranslate => this.titleService.setTitle(appTitleTranslate));
  }

  ngOnInit(): void {
    if (environment.datadog) {
      datadogRum.init(environment.datadog);
      datadogRum.startSessionReplayRecording();
    }
  }
}
