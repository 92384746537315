<mat-sidenav-container [autosize]="true">
  <mat-sidenav [mode]="mobileQuery.matches ? 'over' : 'side'"
               fixedInViewport="true"
               fixedTopGap="56"
               [@.disabled]="!initialized">
    <mat-nav-list>
      <ng-container *ngIf="isAuthenticated">
        <ng-container *ngFor="let item of menuItems">
          <ng-container *requireAllPrivileges="item.privilegeTypes">
            <app-sidenav-menu-item *ngIf="(item.id !== 'groups-btn' && item.id !== 'users-btn') || isPlatformUser"
                                   [icon]="item.icon"
                                   [label]="item.label | translate" [path]="item.path"
            >
            </app-sidenav-menu-item>
          </ng-container>
        </ng-container>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
