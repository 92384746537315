import {Injectable} from "@angular/core";
import {OsmHttpClientService} from '@sesame/sesame-fe-library';
import {Observable} from "rxjs";
import {QueryBuilder} from "src/app/shared/util/query-builder";

@Injectable({
  providedIn: 'root'
})
export class OperationalOrganisationService {

  constructor(private readonly http: OsmHttpClientService) {
  }

  updateOperationalOrganisation(
    organisationId: string,
    operationalOrganisationIds: string[],
    osmDataMaster: string
  ): Observable<void> {
    const url = QueryBuilder.forPath('operational-organisations/{organisationId}')
      .withPathVariable("organisationId", organisationId)
      .build();

    return this.http.put(url, { osmDataMaster, operationalOrganisationIds })
  }

  signOutFromSchoolManagementSystem(organisationId: string): Observable<void> {
    const url = QueryBuilder.forPath('organisations/{organisationId}/sms')
      .withPathVariable("organisationId", organisationId)
      .build();

    return this.http.delete(url);
  }

  deleteDataSchoolManagementSystem(organisationId: string): Observable<void> {
    const url = QueryBuilder.forPath('organisations/{organisationId}/purge')
      .withPathVariable("organisationId", organisationId)
      .build();

    return this.http.delete(url);
  }

}