import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MainSidevanPanelService} from 'src/app/shared/service/main-sidevan-panel.service';
import {LibBaseComponent} from '@sesame/sesame-fe-library';
import {MainSidevanPanelComponent} from 'src/app/core/components/main-sidevan-panel/main-sidevan-panel.component';
import {AppVariables} from 'src/app/shared/util/app-variables';
import {NavigationItem} from 'src/app/shared/models/navigation-item';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-secured-content-page',
  templateUrl: './secured-content-page.component.html',
  styleUrls: ['./secured-content-page.component.scss']
})
export class SecuredContentPageComponent extends LibBaseComponent implements OnInit, AfterViewInit {
  @ViewChild('sidenavPanel')
  sidenavPanel: MainSidevanPanelComponent;
  entryPoint = '';
  menuItems: NavigationItem[];

  constructor(
    private readonly sidenavPanelService: MainSidevanPanelService
  ) {
    super();
  }

  ngOnInit(): void {
    this.entryPoint = environment.entryPoint;
    this.menuItems = AppVariables.navigationItems[this.entryPoint];
  }

  ngAfterViewInit(): void {
    this.sidenavPanelService.registerSidenavPanel(this.sidenavPanel);
  }
}
