import {Component, OnInit} from '@angular/core';
import {AppVariables} from 'src/app/shared/util/app-variables';
import {MainSidevanPanelService} from 'src/app/shared/service/main-sidevan-panel.service';
import {Router} from '@angular/router';
import {OnAfterCreate} from 'src/app/shared/util/on-after-create';
import {
  LibBaseComponent,
  LibOrganisationContextService,
  MediaMatcherService,
} from '@sesame/sesame-fe-library';
import {environment} from 'src/environments/environment';
import {Observable, of, switchMap} from "rxjs";
import {Organisation} from "src/app/shared/models/organisation";
import {OrganisationQuery} from "src/app/shared/service/organisation-query";

@Component({
  selector: 'app-page',
  templateUrl: './app-page.component.html',
  styleUrls: ['./app-page.component.scss']
})
export class AppPageComponent extends LibBaseComponent implements OnAfterCreate, OnInit {

  organisation$: Observable<Organisation | null>;

  readonly menuItems = AppVariables.navigationItems[environment.entryPoint]
    .filter(item => item.id !== 'home');

  constructor(
    private readonly sidenavPanelService: MainSidevanPanelService,
    private readonly router: Router,
    private readonly mediaMatcher: MediaMatcherService,
    private readonly organisationContextService: LibOrganisationContextService,
    private readonly organisationQuery: OrganisationQuery
  ) {
    super();
  }

  ngOnInit(): void {
    this.organisation$ = environment.isCustomerServiceContext ?
      of(null) :
      this.organisationContextService.getCurrent().pipe(
        switchMap(myOrganisation => myOrganisation ? this.organisationQuery.findOne(myOrganisation.organisationId) : of(null))
      );
  }

  onAfterCreate(): void {
    if (environment.isCustomerServiceContext) {
      this.sidenavPanelService.close();
    }
  }

  navigate(path: string): void {
    this.router.navigate([path]).then(() => {
      if (!this.mediaMatcher.matchMedia().matches) {
        this.sidenavPanelService.open();
      }
    });
  }
}
