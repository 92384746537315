import {Component} from '@angular/core';
import {Language, LibBaseComponent, LibLanguageService} from "@sesame/sesame-fe-library";
import {AuthService} from "@auth0/auth0-angular";
import {environment} from "src/environments/environment";

@Component({
  selector: 'app-result-page',
  templateUrl: './result-page.component.html',
  styleUrls: ['./result-page.component.scss']
})
export class ResultPageComponent extends LibBaseComponent {
  readonly environment = environment;
  protected currentLanguage: Language;

  constructor(
    readonly authService: AuthService,
    protected readonly languageService: LibLanguageService
  ) {
    super();
    this.subscribeOnCurrentLanguage();
  }

  onLoginClick(): void {
    this.authService.loginWithRedirect({
      authorizationParams: {language: this.currentLanguage}
    });
  }

  private subscribeOnCurrentLanguage() {
    this.languageService.getCurrent()
      .pipe(this.takeUntilDestroy())
      .subscribe(language => {
        this.currentLanguage = language;
      });
  }

}
