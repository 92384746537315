import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterLabelComponent } from './components/filter-label/filter-label.component';
import {MatIconModule} from '@angular/material/icon';
import {IconModule} from "src/app/shared/module/icon/icon.module";



@NgModule({
  declarations: [FilterLabelComponent],
  exports: [
    FilterLabelComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    IconModule
  ]
})
export class FilterLabelModule { }
