import {Component, Injector, OnInit} from '@angular/core';
import {
  LibBaseComponent, LibOrganisationContextService,
  TableColumn,
  TableConfiguration,
  TableInfiniteScrollService,
  TableRow,
  TableScrollEvent
} from "@sesame/sesame-fe-library";
import {ImportMetadata, SmsImportStatus} from "src/app/shared/models/sms-data-import";
import {SmsImportTableView} from 'src/app/features/organisations/models/sms-import-table-view';
import {Subject} from 'rxjs';
import {SmsImportQuery} from 'src/app/shared/service/sms-import-query';
import {ActivatedRoute} from "@angular/router";
import {environment} from "src/environments/environment";


@Component({
  selector: 'app-sms-data-import',
  templateUrl: './sms-data-import.component.html',
  styleUrls: ['./sms-data-import.component.scss']
})
export class SmsDataImportComponent extends LibBaseComponent implements OnInit {

  private readonly pageSize = 10;
  private lastIndex = 0;
  currentStatus = SmsImportStatus.STARTED;
  readonly allStatuses = [SmsImportStatus.STARTED, SmsImportStatus.FAILED, SmsImportStatus.FINISHED];
  private readonly displayedColumns: TableColumn[] = [
    TableColumn.withLabel('status', 'organisation.user.sms-data-import.table-column.status'),
    TableColumn.withLabel('packageId', 'organisation.user.sms-data-import.table-column.package-id'),
    TableColumn.withLabel('processedAt', 'organisation.user.sms-data-import.table-column.processed-at'),
    TableColumn.withLabel('description', 'organisation.user.sms-data-import.table-column.description'),
  ];
  private endOfList = false;

  tableConfiguration: TableConfiguration<SmsImportTableView>;
  tableScroll$ = new Subject<TableScrollEvent>();
  organisationId: string;

  constructor(
    private readonly smsImportQuery: SmsImportQuery,
    private readonly route: ActivatedRoute,
    private readonly injector: Injector,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(this.takeOne())
      .subscribe(paramMap => this.organisationId = paramMap.get('id'));

    if (!environment.isCustomerServiceContext) {
      this.subscribeOnOrganisationContext();
    }

    this.tableConfiguration = this.createTableConfiguration();
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.subscribeOnTableScroll();
  }

  private createTableConfiguration(): TableConfiguration<SmsImportTableView> {
    return TableConfiguration.default(
      this.displayedColumns,
      [] as TableRow<SmsImportTableView>[],
      this.endOfList
    )
      .withTableScroll(this.tableScroll$)
      .withRequiredPrivileges([]);
  }

  private subscribeOnTableScroll(): void {
    this.tableScroll$
      .pipe(this.takeUntilDestroy())
      .subscribe(event => {
        const infiniteScrollService = new TableInfiniteScrollService(event);
        if (infiniteScrollService.canFetchMoreData(this.endOfList)) {
          this.loadData();
        }
      });
  }

  onCurrentStatusChange(currentStatus: SmsImportStatus): void {
    this.currentStatus = currentStatus;
    this.lastIndex = 0;
    this.endOfList = false;
    this.tableConfiguration.isEndOfList = this.endOfList;
    this.tableConfiguration.data = [];
    this.tableConfiguration = Object.assign({}, this.tableConfiguration);
    this.loadData();
  }

  loadData(): void {
    this.smsImportQuery.findAll(this.currentStatus, this.lastIndex, this.pageSize, this.organisationId)
      .pipe(this.takeOne())
      .subscribe(response => {
        this.lastIndex = response.lastIndex;
        this.updateTableConfiguration(response.importMetadata);
      });
  }

  private updateTableConfiguration(importMetadata: ImportMetadata[]): void {
    const tableRows = SmsImportTableView.transformIntoTableView(importMetadata)
      .map((tableView: SmsImportTableView) => new TableRow<SmsImportTableView>(
        tableView.id.value,
        tableView
      ));

    this.tableConfiguration.isEndOfList = this.endOfList;
    this.tableConfiguration.data = this.tableConfiguration.data.concat(tableRows);
    this.tableConfiguration = Object.assign({}, this.tableConfiguration);
  }

  private subscribeOnOrganisationContext(): void {
    const organisationContextService = this.injector.get(LibOrganisationContextService);
    organisationContextService.getCurrent().pipe(
      this.takeUntilDestroy()
    ).subscribe(organisation => {
      this.organisationId = organisation.organisationId;
      this.tableConfiguration = this.createTableConfiguration();
      this.loadData();
    })
  }

}
