import {TableCell} from "@sesame/sesame-fe-library";
import {OperationalOrganisation} from "@sesame/sesame-fe-library";

export class OperationalOrganisationTableView {

  constructor(
    public name: TableCell,
    public id: TableCell,
    public city: TableCell,
  ) {
  }

  static transformIntoTableView(operationalOrganisations: OperationalOrganisation[]): OperationalOrganisationTableView[] {
    return operationalOrganisations?.map(operationalOrganisation => {
      return new OperationalOrganisationTableView(
        new TableCell(operationalOrganisation.name),
        new TableCell(operationalOrganisation.externalId),
        new TableCell(operationalOrganisation.city),
      );
    });
  }

}