import {CanActivate, CanActivateChild, CanLoad} from '@angular/router';
import {Injectable} from "@angular/core";
import {AuthService} from "@auth0/auth0-angular";
import {take, tap} from "rxjs/operators";
import {LibLanguageService} from "@sesame/sesame-fe-library";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private auth: AuthService, private languageService: LibLanguageService) {
  }

  canLoad(route: any, segments: any): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(take(1));
  }

  canActivate(next: any, state: any): Observable<boolean> {
    return this.redirectIfUnauthenticated(state);
  }

  canActivateChild(childRoute: any, state: any): Observable<boolean> {
    return this.redirectIfUnauthenticated(state);
  }

  redirectIfUnauthenticated(state: any): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(tap((loggedIn) => {
      if (!loggedIn) {
        this.languageService.getCurrent().pipe(take(1))
          .subscribe(language =>
            this.auth.loginWithRedirect({
              authorizationParams: {language: language.code},
              appState: {target: state.url}
            })
          );
      } else {
        return true;
      }
    }));
  }
}
