import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductsList, ProductsListCriteria} from 'src/app/shared/models/products-list';
import {ProductQuery} from 'src/app/shared/service/product.query';
import {map} from 'rxjs/operators';
import {Product} from 'src/app/shared/models/product';

@Component({
  selector: 'app-products-list-page',
  templateUrl: './products-list-page.component.html',
  styleUrls: ['./products-list-page.component.scss']
})
export class ProductsListPageComponent implements OnInit {

  $products: Observable<ProductsList>;
  displayedColumns: string[];
  dataSource: Observable<Product[]>;

  constructor(private productsService: ProductQuery) {
  }

  ngOnInit(): void {
    this.$products = this.productsService.findAll(ProductsListCriteria.empty());
    this.displayedColumns = ['productId', 'shortName'];
    this.dataSource = this.$products.pipe(
      map(a => a.products)
    );
  }


}
