import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-conditional-label[default][value]',
  templateUrl: './conditional-label.component.html',
  styleUrls: ['./conditional-label.component.scss']
})
export class ConditionalLabelComponent implements OnInit {

  @Input() value: string;
  @Input() default: string;
  @Input() condition: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
