import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  faArchive,
  faArrowsRotate,
  faBan, faBarcodeRead,
  faBars, faBuildingColumns,
  faCancel,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleMinus,
  faCircleUser,
  faCloudArrowUp,
  faCopy,
  faDownload,
  faCloudArrowDown,
  faEllipsis,
  faEnvelope,
  faExternalLink,
  faEye,
  faFloppyDisk, faFolder, faGear, faHistory, faHouse,
  faInboxIn,
  faKey,
  faKeySkeleton, faLock,
  faMagnifyingGlass,
  faPen,
  faPlus, faPuzzlePiece, faQuestion, faRightFromBracket, faShuffle,
  faTrash, faUser, faUserGroup, faUserMinus,
  faUserPlus,
  faUsers, faUserTie,
  faWarning,
  faXmark,
  faCircleXmark,
  faHourglassStart,
} from '@fortawesome/pro-regular-svg-icons';

import {faCircleExclamation as faCircleExclamationSolid} from '@fortawesome/free-solid-svg-icons';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [
    FontAwesomeModule
  ]
})
export class IconModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCircleExclamationSolid, faArchive, faHistory, faBarcodeRead, faUserGroup, faGear, faBuildingColumns, faUserTie, faPuzzlePiece, faRightFromBracket, faLock, faCloudArrowUp, faUsers, faInboxIn, faBan, faUserPlus, faCopy, faDownload, faCloudArrowDown, faExternalLink, faTrash, faCircleMinus, faKey, faCircleUser, faPlus, faXmark, faPen, faChevronDown, faChevronUp, faChevronLeft, faKeySkeleton, faEye, faMagnifyingGlass, faCheck, faEnvelope, faArrowsRotate, faEllipsis, faCircleCheck, faCircleExclamation, faCancel, faBars, faFloppyDisk, faShuffle, faFolder, faUserMinus, faUser, faHouse, faQuestion, faWarning, faCircleXmark, faHourglassStart)
  }
}
