import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DataMasterType, Organisation} from 'src/app/shared/models/organisation';
import {
  ButtonType,
  Color,
  DialogConfiguration,
  DialogService,
  DialogV2Configuration,
  DialogV2Service,
  LibBaseComponent,
  LocationV2,
  SnackBarService,
  TableColumn,
  TableConfiguration,
  TableRow
} from '@sesame/sesame-fe-library';
import {OperationalOrganisationTableView} from "src/app/features/users/models/operational-organisation-table-view";
import {catchError, filter, switchMap, tap} from "rxjs/operators";
import {UpdatePlatformUserCommand} from "src/app/shared/service/update-platform-user-command";
import {
  DeactivateOrganisationDialogComponent,
  DeactivateOrganisationDialogData
} from "src/app/shared/module/organisation-detail/deactivate-organisation-dialog/deactivate-organisation-dialog.component";
import {AppVariables} from "src/app/shared/util/app-variables";
import {ConfigurePlatformUserDialogData} from "src/app/shared/module/organisation-detail/configure-platform-user-dialog/configure-platform-user-dialog-models";
import {BehaviorSubject, EMPTY, NEVER, Subject} from "rxjs";
import {environment} from "src/environments/environment";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ConfigurePlatformUserDialogComponent} from '../configure-platform-user-dialog/configure-platform-user-dialog.component';
import {
  ChangeSchoolDataMasterSystemDialogComponent,
  ChangeSchoolDataMasterSystemDialogData,
  ChangeSchoolDataMasterSystemDialogResult,
  ChangeSchoolDataMasterSystemStatus
} from "src/app/shared/module/organisation-detail/change-school-data-master-system-dialog/change-school-data-master-system-dialog.component";
import {OperationalOrganisationService} from "src/app/shared/service/operational-organisation.service";
import {ActionV2} from "@sesame/sesame-fe-library/lib/dialog-v2/service/dialog-v2";
import {
  DeleteDataSchoolDataMasterSystemDialogComponent,
  DeleteDataSchoolDataMasterSystemDialogData
} from "src/app/shared/module/organisation-detail/delete-data-school-data-master-system-dialog/delete-data-school-data-master-system-dialog.component";

@Component({
  selector: 'app-organisations-details-info-panel',
  templateUrl: './organisation-details-info-panel.component.html',
  styleUrls: ['./organisation-details-info-panel.component.scss']
})
export class OrganisationDetailsInfoPanelComponent extends LibBaseComponent implements OnChanges {
  readonly SWITCH_ACTION: ActionV2 = {
    text: new BehaviorSubject<string>('switchOff'),
    id: 'switchOff',
    style: {color: Color.primary, buttonType: ButtonType.flat},
    onTrigger: new Subject<any>(),
    isEnabled: new BehaviorSubject(true),
    isVisible: new BehaviorSubject(true),
    location: LocationV2.right,
    isTerminal: true
  };
  readonly CANCEL_ACTION: ActionV2 = {
    text: new BehaviorSubject<string>('cancel'),
    id: 'cancel',
    style: {color: Color.primary, buttonType: ButtonType.stroked},
    onTrigger: new Subject<any>(),
    isEnabled: new BehaviorSubject(true),
    isVisible: new BehaviorSubject(true),
    location: LocationV2.right,
    isTerminal: true
  };

  @Input() organisation: Organisation;

  tableConfiguration: TableConfiguration<OperationalOrganisationTableView>;

  isCustomerServiceContext = environment.isCustomerServiceContext;

  protected readonly DataMasterType = DataMasterType;

  private readonly displayedColumns: any[] = [
    TableColumn.withLabel('name', 'organisation.operationalOrganisations.name'),
    TableColumn.withLabel('id', 'organisation.operationalOrganisations.id'),
    TableColumn.withLabel('city', 'organisation.operationalOrganisations.city'),
  ];

  constructor(
    private readonly updatePlatformUserCommand: UpdatePlatformUserCommand,
    private readonly router: Router,
    private readonly dialogService: DialogService,
    private readonly dialogV2Service: DialogV2Service,
    private readonly translate: TranslateService,
    private readonly snackBarService: SnackBarService,
    private readonly operationalOrganisationService: OperationalOrganisationService,
  ) {
    super();
    (this.SWITCH_ACTION.text as Subject<any>).next(translate.instant('button.yesSwitchOff'));
    (this.CANCEL_ACTION.text as Subject<any>).next(translate.instant('button.cancel'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.organisation) {
      return;
    }

    this.tableConfiguration = this.getTableConfiguration();
  }

  editLinkedOrganisations(): void {
    this.showConfigurePlatformUserDialog(true);
  }

  private getTableConfiguration(): TableConfiguration<any> {
    const operationalOrganisations = OperationalOrganisationTableView.transformIntoTableView(this.organisation.operationalOrganisations);
    const tableRows = operationalOrganisations?.map(operationsOrganisation =>
      new TableRow<OperationalOrganisationTableView>(operationsOrganisation.id.value, operationsOrganisation)
    );

    return TableConfiguration
      .default(this.displayedColumns, tableRows, true);
  }

  public onIsPlatformUserFormChange(isPlatformUserField: boolean) {
    if (!!isPlatformUserField) {
      this.showConfigurePlatformUserDialog(false);
    } else {
      this.organisation.isPlatformUser = true;
      this.organisation = Object.assign({}, this.organisation);
      this.showDeactivateOrganisationDialog();
    }
  }

  private showConfigurePlatformUserDialog(editMode: boolean): void {
    const dialogConfig = DialogConfiguration.default<ConfigurePlatformUserDialogData, void>(
      ConfigurePlatformUserDialogComponent, null)
      .withTitle(this.translate.get('organisation.user.configure-platform-user-dialog.title'))
      .withStyle(AppVariables.dialog.style.default)
      .withPayload({
        organisation: this.organisation,
        editMode: editMode,
      })
      .withRightTopCornerCloseButton();

    this.dialogService.open(dialogConfig)
      .afterClosed()
      .pipe(
        this.takeUntilDestroy(),
        tap(organisation => {
          if (!organisation?.organisationId) {
            // when dialog was closed with no submit
            this.organisation = Object.assign({}, this.organisation);
          } else {
            this.organisation.operationalOrganisations = organisation.operationalOrganisations;
            this.updateOperationalOrganisationsTable();
          }
        }),
        filter(organisationId => !!organisationId),
        switchMap(() => {
          if (editMode) {
            return EMPTY;
          } else {
            return this.updatePlatformUserCommand.updatePlatformUser(this.organisation.organisationId, true);
          }
        })
      )
      .subscribe(() => {
        this.router.navigateByUrl('/empty', {skipLocationChange: true}).then(() =>
          this.router.navigate([`organisations/${this.organisation.organisationId}`])
        );
      });
  }

  private showDeactivateOrganisationDialog() {
    const dialogConfig = DialogV2Configuration.default<DeactivateOrganisationDialogData, void>(
      DeactivateOrganisationDialogComponent, null)
      .withTitle(this.translate.get('organisation.user.deactivate-organisation-dialog.title'))
      .withStyle(AppVariables.dialog.style.small)
      .withPayload({organisation: this.organisation})
      .withAction(this.CANCEL_ACTION)
      .withAction(this.SWITCH_ACTION);

    this.dialogV2Service.open(dialogConfig);
    this.SWITCH_ACTION.onTrigger
      .pipe(
        this.takeOne(),
        switchMap(() => this.operationalOrganisationService.signOutFromSchoolManagementSystem(this.organisation.organisationId)),
        catchError(() => {
          this.snackBarService.showError(this.translate.instant("organisation.user.deactivate-organisation-dialog.error"))
          return NEVER;
        })
      )
      .subscribe(() => {
        this.router.navigateByUrl('/empty', {skipLocationChange: true}).then(() =>
          this.router.navigate([`organisations/${this.organisation.organisationId}`]).then(() =>
            this.snackBarService.showSuccess(this.translate.instant("organisation.user.deactivate-organisation-dialog.success"))));
      });
  }

  private updateOperationalOrganisationsTable() {
    const operationsOrganisations = OperationalOrganisationTableView.transformIntoTableView(this.organisation.operationalOrganisations);
    const tableRows = operationsOrganisations?.map(operationsOrganisation =>
      new TableRow<OperationalOrganisationTableView>(operationsOrganisation.id.value, operationsOrganisation)
    );
    this.tableConfiguration.data = tableRows;
    this.tableConfiguration = Object.assign({}, this.tableConfiguration);
  }

  changePlatformUser() {
    const onChangeSchoolDataMasterSystemSubmit = new Subject<ChangeSchoolDataMasterSystemDialogResult>();

    const dialogConfig = DialogConfiguration.default<ChangeSchoolDataMasterSystemDialogData, ChangeSchoolDataMasterSystemDialogResult>(
      ChangeSchoolDataMasterSystemDialogComponent, onChangeSchoolDataMasterSystemSubmit)
      .withTitle(this.translate.get('organisation.user.change-organisation-dialog.title'))
      .withStyle(AppVariables.dialog.style.default)
      .withPayload({organisation: this.organisation})

    onChangeSchoolDataMasterSystemSubmit
      .pipe(
        this.takeUntilDestroy(),
        filter(result => result.status != ChangeSchoolDataMasterSystemStatus.CANCELED),
        tap(result => {
          if (result.status === ChangeSchoolDataMasterSystemStatus.SIGNED_OUT) {
            this.router.navigateByUrl('/empty', {skipLocationChange: true}).then(() =>
              this.router.navigate([`organisations/${this.organisation.organisationId}`]).then(() =>
                this.snackBarService.showWarning(
                  this.translate.instant('organisation.user.change-organisation-dialog.organisation-unlinked.point1') +
                  this.translate.instant('organisation.user.change-organisation-dialog.organisation-unlinked.point2')
                ))
            );
          }
        }),
        filter(result => {
          return result.status == ChangeSchoolDataMasterSystemStatus.CHANGED
        }),
        tap(result => {
          this.updateOperationalOrganisationsTable();
        }),
        switchMap(() => {
          return this.updatePlatformUserCommand.updatePlatformUser(this.organisation.organisationId, true)
        }),
        catchError(() => {
          this.snackBarService.showError(
            this.translate.instant('organisation.user.change-organisation-dialog.update-failed')
          )
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.router.navigateByUrl('/empty', {skipLocationChange: true}).then(() =>
          this.router.navigate([`organisations/${this.organisation.organisationId}`]).then(() =>
            this.snackBarService.showSuccess(
              this.translate.instant('organisation.user.change-organisation-dialog.success')
            ))
        );
      });


    this.dialogService.open(dialogConfig)
  }

  openDeleteOrganisationDataDialog() {
    const onDialogClose = new Subject<void>();
    const dialogConfiguration = DialogConfiguration
      .default<DeleteDataSchoolDataMasterSystemDialogData, void>(
        DeleteDataSchoolDataMasterSystemDialogComponent,
        onDialogClose
      );

    dialogConfiguration
      .withPayload({organisation: this.organisation})
      .withStyle(AppVariables.dialog.style.default)
      .withTitle(this.translate.get('organisation.user.delete-organisation-data-dialog.title'))
      .withRightTopCornerCloseButton();

    onDialogClose
      .pipe(this.takeUntilDestroy())
      .subscribe(() => this.router.navigateByUrl('/empty', {skipLocationChange: true})
        .then(() => this.router.navigate([`organisations/${this.organisation.organisationId}`])));

    this.dialogService.open(dialogConfiguration);
  }

}
