<div class="d-flex justify-content-start gap-2">
  <label>{{ 'organisation.integration.adapter-org-id' | translate }}:</label>
  <span>{{ organisationClient.organisationId }}</span>
</div>
<div class="d-flex justify-content-start gap-2">
  <label>{{ 'organisation.integration.api-token' | translate }}:</label>
  <a *ngIf="!isClientSecretVisible" class="show-text-link"
     (click)="toggleClientSecretVisibility()">
    {{ 'organisation.integration.show-client-secret' | translate }}
  </a>
  <ng-container *ngIf="isClientSecretVisible">
    <span>{{ organisationClient.apiToken }}</span>
    <a class="show-text-link" (click)="toggleClientSecretVisibility()">
      {{ 'organisation.integration.hide-client-secret' | translate }}
    </a>
  </ng-container>
</div>

