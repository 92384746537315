import {Component, OnInit} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';

@Component({
  selector: 'app-authorization-login',
  templateUrl: './authorization-login.component.html',
  styleUrls: ['./authorization-login.component.scss']
})
export class AuthorizationLoginComponent implements OnInit {

  constructor(public readonly auth: AuthService) {}

  ngOnInit(): void {
  }

}
