<p>products-page works!</p>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!-- Id Column -->
  <ng-container matColumnDef="productId">
    <th mat-header-cell *matHeaderCellDef> Id </th>
    <td mat-cell *matCellDef="let element"> {{element.productId}} </td>
  </ng-container>
  <!-- Name Column -->
  <ng-container matColumnDef="shortName">
    <th mat-header-cell *matHeaderCellDef> Short name </th>
    <td mat-cell *matCellDef="let element"> {{element.shortName}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
