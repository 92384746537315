import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SmsDataImportComponent} from "src/app/features/sms-data-import/components/sms-data-import/sms-data-import.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {TableModule} from "@sesame/sesame-fe-library";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import {SmsDataImportPageComponent} from "src/app/features/sms-data-import/pages/sms-data-import-page/sms-data-import-page.component";
import {OrganisationDetailModule} from "src/app/shared/module/organisation-detail/organisation-detail.module";
import {GoBackFromSmsDataImportComponent} from "src/app/features/sms-data-import/components/go-back-from-sms-data-import/go-back-from-sms-data-import.component";


@NgModule({
  declarations: [
    SmsDataImportComponent,
    SmsDataImportPageComponent,
    GoBackFromSmsDataImportComponent,
  ],
  exports: [
    SmsDataImportComponent,
    SmsDataImportPageComponent,
    GoBackFromSmsDataImportComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    TableModule,
    TranslateModule,
    FormsModule,
    OrganisationDetailModule,
  ]
})
export class SmsDataImportModule {
}
