import {Injectable} from '@angular/core';
import {ProductsList, ProductsListCriteria} from 'src/app/shared/models/products-list';
import {Observable} from 'rxjs';
import {LmsHttpClient} from '@sesame/sesame-fe-library';
import {QueryBuilder} from 'src/app/shared/util/query-builder';
import {ProductDetails} from 'src/app/shared/models/product';

@Injectable({
  providedIn: 'root'
})
export class ProductQuery {

  constructor(private readonly lmsHttpClient: LmsHttpClient) {
  }

  findAll(criteria: ProductsListCriteria): Observable<ProductsList> {
    const query = QueryBuilder.forPath('products')
      .withRequestParamIfDefined('productId', criteria.productId)
      .withRequestParamIfDefined('shortName', criteria.productName)
      .withRequestParamIfDefined('eanCode', criteria.productEan)
      .withRequestParamIfDefined('lastIndex', criteria.lastIndex)
      .build();
    return this.lmsHttpClient.get<ProductsList>(query);
  }

  findOne(productId: string): Observable<ProductDetails> {
    const query = QueryBuilder.forPath('products/{productId}')
      .withPathVariable('productId', productId)
      .build();
    return this.lmsHttpClient.get<ProductDetails>(query);
  }

}
