import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

export class CustomValidators {

  public static readonly URL_PATTERN = /^https?:\/\/([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?(\?[\w%=+.&-]+)?(#\w+)?$/;

  /** Apply given validator only when controlToCheck has given value. */
  static if(controlToCheck: AbstractControl, value: any, validatorFn: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const fieldValue = controlToCheck.value === value;
      return fieldValue ? validatorFn(control) : null;
    };
  }

  /** Apply given validator only when controlToCheck has one of the given values. */
  static ifAny(controlToCheck: AbstractControl, values: any[], validatorFn: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const fieldValue = values.indexOf(controlToCheck.value) >= 0;
      return fieldValue ? validatorFn(control) : null;
    };
  }

  /** Apply given validator only when controlToCheck value is different than value. */
  static ifNot(controlToCheck: AbstractControl, value: any, validatorFn: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const fieldValue = controlToCheck.value === value;
      return fieldValue ? null : validatorFn(control);
    };
  }

  /** Apply given validator only when controlToCheck has any value. */
  static ifAnyValue(controlToCheck: AbstractControl, validatorFn: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return controlToCheck.value !== null ? validatorFn(control) : null;
    };
  }

  /** Apply given validator only when field has any value. */
  static ifHasAnyValue(validatorFn: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value !== null ? validatorFn(control) : null;
    };
  }

  /** Validator that requires the control have a non-empty value only when controlToCheck value is equal to given value. */
  static requiredIf(controlToCheck: AbstractControl, value: any): ValidatorFn {
    return CustomValidators.if(controlToCheck, value, Validators.required);
  }

  static validatedIf(controlToCheck: AbstractControl, value: any, validator: ValidatorFn): ValidatorFn {
    return CustomValidators.if(controlToCheck, value, validator);
  }

  /** Validator that requires the control have a non-empty value only when function returns true */
  static requiredIfAsync(func: () => boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (func()) {
        return control.value != null ? null : {required: true};
      } else {
        return null;
      }
    };
  }

  static isSameAsDefault(defaultValue: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value === defaultValue) {
        return {'isSameAsDefault': true};
      } else {
        return null;
      }
    };
  }

  /** Validator that requires the control have a non-empty value only when controlToCheck value is any of the to given value. */
  static requiredIfAny(controlToCheck: AbstractControl, values: any[]): ValidatorFn {
    return CustomValidators.ifAny(controlToCheck, values, Validators.required);
  }

  /** Validator that requires the control have a non-empty value only when controlToCheck value is not equal to given value. */
  static requiredIfNot(controlToCheck: AbstractControl, value: any): ValidatorFn {
    return CustomValidators.ifNot(controlToCheck, value, Validators.required);
  }

  /** Validator that requires the control's value be greater than controlToCheck's value. */
  static greaterThan(controlToCheck: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return controlToCheck.value < control.value ? null : {greaterThan: true};
    };
  }

  /** Validator that requires the control's value be greater than controlToCheck's value. */
  static greaterThanAsync(func: () => number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return func() < control.value ? null : {greaterThan: true};
    };
  }

  /** Validator that requires the control's value be equal or greater than function's return value. */
  static equalOrGreaterThanAsync(func: () => number | Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return func() <= control.value ? null : {equalOdGreaterThan: true};
    };
  }

  /** Validator that requires the control's value be equal with provided form control */
  static equal(controlToCheck: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return controlToCheck.value !== control.value ? null : {equal: true};
    };
  }

  /**
   * The issue lies in the email validation logic.
   * In Angular, the Validators.email checks if the input string conforms to the basic email pattern
   * defined by the HTML5 specification. The pattern allows certain formats that might not
   * include a top-level domain (TLD). This means xyz@sanoma is considered a valid email according to this basic pattern.
   */
  static strictEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const strictEmailPattern = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+\.[a-z]{2,}$/i;
      return strictEmailPattern.test(control.value) ? null : {'email': true};
    };
  }

  static validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.updateValueAndValidity({onlySelf: false, emitEvent: false});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}

