<h5 class="subtitle">
  {{ 'organisation.user.configure-platform-user-dialog.subtitle' | translate: {organisationName: payload.organisation.name} }}
</h5>
<mat-stepper orientation="vertical" linear="true" #stepper [selectedIndex]="firstStepIndex">
  <mat-step [completed]="selectedDataMasterSystem" [editable]="!payload.editMode">
    <ng-template matStepLabel>{{ 'organisation.user.configure-platform-user-dialog.step-1.title' | translate }}
    </ng-template>

    <app-select-data-master-system-panel
            (dataMasterSelected)="selectedDataMasterSystem = $event"
            [organisation]="payload.organisation"
            [disabled]="payload.editMode">
    </app-select-data-master-system-panel>

    <section class="actions">
      <button mat-flat-button matStepperNext color="primary" [disabled]="!payload.editMode && !selectedDataMasterSystem">
        {{ 'organisation.user.configure-platform-user-dialog.step-1.next-step-button' | translate }}
      </button>
    </section>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>
      {{ 'organisation.user.configure-platform-user-dialog.step-2.title' | translate }}
    </ng-template>

    <app-select-linked-organisation-panel class="w-100" [organisation]="payload.organisation"
                                          (organisationsSelected)="selectedOrganisations = $event"></app-select-linked-organisation-panel>
    <section class="actions">
      <button mat-stroked-button color="primary" (click)="close()">
        {{ 'organisation.user.configure-platform-user-dialog.step-2.cancel-button' | translate }}
      </button>
      <button mat-flat-button color="primary" (click)="submit()">
        {{ 'organisation.user.configure-platform-user-dialog.step-2.save-button' | translate }}
      </button>
    </section>
  </mat-step>
</mat-stepper>