export class Auth0ErrorInterpreter {
  static getMessageCode(message: string): string {
    const errorCode = message.match(/\[([^)]+)\]/)?.[1];
    if (errorCode === '00001') {
      return 'auth0-error.unverified';
    } else if (errorCode === '00002') {
      return 'auth0-error.unallowed';
    } else if (errorCode === '00003') {
      return 'auth0-error.declined';
    } else if (message === 'user is blocked') {
      return 'auth0-error.user-is-blocked';
    }
    return 'auth0-error.unknown';
  }
}
