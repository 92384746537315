<ng-container *ngIf="organisation">
  <section class="mb-3 mt-5">
    <app-page-title>
      <ng-container text>
        {{ organisation.name }}<span
              *ngIf="organisation.name2 && isCustomerServiceContext"> - {{ organisation.name2 }}</span>
        ({{ organisation.externalId }})
      </ng-container>
      <ng-container>
        {{ 'pageTitle.organisationDetails' | translate }}
      </ng-container>
    </app-page-title>
  </section>

  <div class="d-flex justify-content-start isExpanded">
    <mat-card class="w-100">
      <mat-card-content>
        <div class="d-flex flex-column">
          <div class="d-flex">
            <section class="d-flex justify-content-between w-50">
              <section class="d-flex gap-5">
                <div class="d-flex flex-column gap-2">
                  <label class="section-label">{{ 'organisation.details' | translate }}</label>
                  <ng-container>
                    <div class="d-flex justify-content-between">
                      <label class="mr-4">{{ 'organisation.id' | translate }}:</label>
                      <span>{{ organisation.externalId }}</span>
                    </div>
                  </ng-container>
                  <ng-container>
                    <div class="d-flex justify-content-between">
                      <label class="mr-4">{{ 'organisation.sector' | translate }}:</label>
                      <span>{{ organisation.sector }}</span>
                    </div>
                  </ng-container>
                </div>
                <div class="d-flex flex-column gap-2">
                  <label class="section-label">{{ 'organisation.address' | translate }}</label>
                  <div class="d-flex justify-content-between">
                    <label class="mr-4">{{ 'organisation.city' | translate }}:</label>
                    <span>{{ organisation.city }}</span>
                  </div>
                  <div class="d-flex justify-content-between" *ngIf="organisation.street">
                    <label class="mr-4">{{ 'organisation.street' | translate }}:</label>
                    <span>{{ organisation.street }}</span>
                  </div>
                  <div class="d-flex justify-content-between" *ngIf="organisation.houseNumber">
                    <label class="mr-4">{{ 'organisation.houseNumber' | translate }}:</label>
                    <span>{{ organisation.houseNumber }}</span>
                  </div>
                  <div class="d-flex justify-content-between" *ngIf="organisation.houseNumberSupplement">
                    <label class="mr-4"></label>
                    <span>{{ organisation.houseNumberSupplement }}</span>
                  </div>
                  <div class="d-flex justify-content-between" *ngIf="organisation.postalCode">
                    <label class="mr-4">{{ 'organisation.postalCode' | translate }}:</label>
                    <span>{{ organisation.postalCode }}</span>
                  </div>
                </div>
                <div class="d-flex flex-column gap-2">
                  <label class="section-label">{{ 'organisation.primary_education' | translate }}</label>
                  <div class="d-flex flex-row align-items-center gap-1">
                    <fa-icon class="primary-color"
                             [icon]="['far', organisation.isPrimaryEducation ? 'circle-check' : 'circle-minus']"
                             size="sm"></fa-icon>
                    <span>{{ (organisation.isPrimaryEducation ? 'portal.yes' : 'portal.no') | translate }}</span>
                  </div>
                </div>
              </section>
            </section>
            <section class="d-flex flex-column gap-3 w-50">
              <app-organisation-platform-user-panel
                      (statusChange)="onIsPlatformUserFormChange($event)"
                      [organisation]="organisation">
                <a class="show-text-link" (click)="changePlatformUser()" *ngIf="organisation.dataMaster">
                  {{ 'organisation.platformUser.changeLabel' | translate }}
                </a>
                <a class="show-text-link-delete" (click)="openDeleteOrganisationDataDialog()"
                   *ngIf="organisation.dataMaster">
                  <fa-icon [icon]="['far', 'trash']" size="sm"></fa-icon>
                  {{ 'organisation.platformUser.deleteDataLabel' | translate }}
                </a>
              </app-organisation-platform-user-panel>
              <app-organisation-integration class="mt-4"
                                            *ngIf="organisation.dataMasterType === DataMasterType.EXTERNAL"
                                            [organisation]="organisation">
              </app-organisation-integration>
            </section>
          </div>
          <ng-container *ngIf="organisation.isPlatformUser">
            <div class="d-flex gap-3 justify-content-start mt-5">
              <label class="section-label">{{ 'organisation.linkedOrganisationTitle' | translate }}</label>
              <a *ngIf="isCustomerServiceContext" class="show-text-link" (click)="editLinkedOrganisations()">
                <fa-icon [icon]="['far', 'pen']" size="sm"></fa-icon>
                {{ 'organisation.editLinkedOrganisations' | translate }}
              </a>
            </div>
            <lib-table [config]="tableConfiguration"></lib-table>
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
