import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Organisation} from "src/app/shared/models/organisation";
import {environment} from "src/environments/environment";
import {LibBaseComponent} from "@sesame/sesame-fe-library";
import {PrivilegeType} from "../../../models/privilege-type";

@Component({
  selector: 'app-organisation-platform-user-panel',
  templateUrl: './organisation-platform-user-panel.component.html',
  styleUrls: ['./organisation-platform-user-panel.component.scss']
})
export class OrganisationPlatformUserPanelComponent extends LibBaseComponent implements OnInit, OnChanges {
  readonly PRIVILEGE_TYPE = PrivilegeType;
  @Input() organisation: Organisation;
  isPlatformUserForm: FormGroup<{ isPlatformUser: FormControl<boolean> }>;
  isCustomerServiceContext = environment.isCustomerServiceContext;

  @Output()
  statusChange = new EventEmitter<boolean>();

  constructor(
    private readonly formBuilder: FormBuilder) {
    super();
    this.isPlatformUserForm = this.formBuilder.group({isPlatformUser: [false, [Validators.required]]});
  }

  ngOnInit(): void {
    this.createIsPlatformUserForm();
    this.subscribeOnIsPlatformUserFormChange();
  }

  ngOnChanges(): void {
    if (this.organisation && this.isPlatformUserForm) {
      this.isPlatformUserForm.get('isPlatformUser').setValue(this.organisation.isPlatformUser, {emitEvent: false})
    }
  }

  private createIsPlatformUserForm(): void {
    this.isPlatformUserForm = this.formBuilder.group({isPlatformUser: [this.organisation.isPlatformUser, [Validators.required]]});
  }

  private subscribeOnIsPlatformUserFormChange(): void {
    this.isPlatformUserForm.valueChanges
      .pipe(this.takeUntilDestroy())
      .subscribe(() => {
        const isPlatformUserField = this.isPlatformUserForm.get('isPlatformUser');
        this.statusChange.emit(isPlatformUserField.value);
      });

  }
}
