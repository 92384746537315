import {TableCell, TableCellIcon} from "@sesame/sesame-fe-library";
import {ImportMetadata, SmsImportStatus} from "src/app/shared/models/sms-data-import";

export class SmsImportTableView {

  constructor(
    public id: TableCell<string>,
    public status: TableCell<SmsImportStatus>,
    public processedAt: TableCell<Date>,
    public description: TableCell<string>,
    public packageId: TableCell<string>,
  ) {
  }

  static transformIntoTableView(importMetadata: ImportMetadata[]) {
    return importMetadata
      .map(row => {
        let statusIcon: TableCellIcon;
        switch (row.status) {
          case 'STARTED':
            statusIcon = new TableCellIcon('hourglass-start');
            break;
          case 'FAILED':
            statusIcon = new TableCellIcon('circle-xmark', ['error-color'])
            break;
          case 'FINISHED':
            statusIcon = new TableCellIcon('circle-check', ['success-color']);
            break;
        }

        if (row.descriptions.length) {
          return row.descriptions
            .map(description => new SmsImportTableView(
              new TableCell<string>(row.id),
              new TableCell<SmsImportStatus>(row.status, statusIcon),
              new TableCell<Date>(row.processedAt),
              new TableCell<string>(description),
              new TableCell<string>(row.packageId)
            ));
        } else {
          return [
            new SmsImportTableView(
              new TableCell<string>(row.id),
              new TableCell<SmsImportStatus>(row.status, statusIcon),
              new TableCell<Date>(row.processedAt),
              new TableCell<string>(undefined),
              new TableCell<string>(row.packageId)
            )
          ];
        }
      })
      .reduce((acc, val) => acc.concat(val), []);
  }

}
