import {Injectable} from "@angular/core";
import {OsmHttpClientService} from '@sesame/sesame-fe-library';
import {OrganisationClient} from "src/app/shared/models/organisation";
import {Observable} from "rxjs";
import {QueryBuilder} from "src/app/shared/util/query-builder";

@Injectable({
  providedIn: 'root'
})
export class OrganisationClientCommand {

  constructor(private readonly http: OsmHttpClientService) {
  }

  updateClient(organisationId: string, client: OrganisationClient): Observable<void> {
    const url = QueryBuilder.forPath(`organisations/${organisationId}/client`)
      .withPathVariable('organisationId', organisationId)
      .build();

    return this.http.post<void, OrganisationClient>(url, client);
  }

}