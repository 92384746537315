<section class="wrapper" *ngIf="!isCustomerServiceContext">
  <section class="left-section">
    <div class="title">
      {{ 'landing.title' | translate }}
    </div>
    <button mat-flat-button color="primary" (click)="onLoginClick()">
      {{ 'landing.login' | translate }}
    </button>
  </section>
  <section class="right-section">
    <img src="assets/img/osp_landing_page_image.svg">
  </section>
</section>