import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductSearchComponent} from 'src/app/shared/module/product-search/components/product-search/product-search.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {TableModule} from '@sesame/sesame-fe-library';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {FilterLabelModule} from 'src/app/shared/module/filter-label/filter-label.module';
import {TranslateModule} from '@ngx-translate/core';
import {IconModule} from "src/app/shared/module/icon/icon.module";


@NgModule({
  declarations: [ProductSearchComponent],
  exports: [ProductSearchComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TableModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    FilterLabelModule,
    TranslateModule,
    IconModule
  ]
})
export class ProductSearchModule {
}
