<ng-container *ngIf="organisation.dataMaster === supportedDataMaster.SMARTSCHOOL">
  <div *requireAllPrivileges="[PRIVILEGE_TYPE.MANAGE_ORGANISATION_CLIENT]" class="mr-5">
    <section class="d-flex gap-3">
      <label class="section-label">{{ 'organisation.integration.title' | translate }} {{ organisation.dataMaster | titlecase }}</label>
      <a *ngIf="organisationClient" class="show-text-link" (click)="openIntegrationDialog()">
        <fa-icon [icon]="['far', 'pen']" size="sm" ></fa-icon>
        {{ 'organisation.integration.edit-integration-button' | translate }}
      </a>
      <a *ngIf="organisationClient === null" (click)="openIntegrationDialog()" class="show-text-link">
        <fa-icon [icon]="['far', 'magnifying-glass']" size="sm"></fa-icon>
        {{ 'organisation.integration.add-integration-button' | translate }}
      </a>
    </section>
    <ng-container *ngIf="organisationClient">
      <app-smartschool-integration-details [organisationClient]="$any(organisationClient)"></app-smartschool-integration-details>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="organisation.dataMaster === supportedDataMaster.WISA && !!organisationClient ">
  <div *requireAllPrivileges="[PRIVILEGE_TYPE.MANAGE_ORGANISATION_CLIENT]" class="mr-5">
    <label class="section-label">{{ 'organisation.integration.title' | translate }} {{ organisation.dataMaster | titlecase }}</label>
    <app-wisa-integration-details [organisationClient]="$any(organisationClient)"></app-wisa-integration-details>
  </div>
</ng-container>
<div class="d-flex mt-3 gap-2 show-text-link" (click)="showSmsDataImport()">
  <fa-icon [icon]="['far', 'eye']" size=""></fa-icon>
  <span>{{ 'organisation.user.sms-data-import.view-results-label' | translate }}</span>
</div>