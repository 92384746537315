import {SupportedDataMaster} from '@sesame/sesame-fe-library';
import gitVersion from 'git-version.json';
import {
  Environemnt, SKOLON_CONNECTION_NAME,
  SKOLON_CONNECTION_VALUE, SKOLON_TEST_CONNECTION_NAME,
  SKOLON_TEST_CONNECTION_VALUE, UTBILDNING_CONNECTION_NAME, UTBILDNING_CONNECTION_VALUE
} from "src/environments/model";

const domain = window.location.hostname;
const entryPoint = domain === 'utbildning.dev.csp.sanomalearning.com' ? 'CS' : 'OSS';
const isCustomerServiceContext = entryPoint === 'CS';
export const environment: Environemnt = {

  domain,
  entryPoint,
  isCustomerServiceContext,
  app: {
    version: gitVersion.raw,
    name: entryPoint === 'CS' ? 'Customer Service Portal' : 'MittSanoma Admin',
  },
  production: false,
  envName: 'dev',
  tenantName: 'Utbildning',
  tenant: 'utbildning',
  logo: {
    favIcon: './assets/img/sanoma_favicon.png',
    full: './assets/img/sanoma_favicon.png',
    raw: './assets/img/sanoma_favicon.png',
    showInAccountSettings: false
  },
  lms: {
    baseUrl: 'https://utbildning.dev.licenses.sanomalearning.com'
  },
  cspBackend: {
    baseUrl: 'https://utbildning.dev.csp-backend.sanomalearning.com'
  },
  osm: {
    baseUrl: 'https://utbildning.dev.osm.sanomalearning.com'
  },
  ess: {
    baseUrl: 'https://utbildning.dev.search.sanomalearning.com'
  },
  apd: {
    baseUrl: 'https://utbildning.dev.apd.sanomalearning.com'
  },
  ups: {
    baseUrl: 'https://utbildning.dev.userprofile.sanomalearning.com'
  },
  externalStorage: {
    media: {
      baseUrl: 'https://media.sesame-prd.mbgaws.net/customer-service-portal/dev/',
      filename: {
        students: 'student+example+csv-file.csv',
        studentsPE: 'student+PE+example+csv-file.csv',
        teachers: 'teacher+example+csv-file.csv',
        groups: 'group+example+csv-file.csv'
      }
    }
  },
  auth0: {
    config: {
      clientId: entryPoint === 'CS' ? 'xwF3NmnpyX2OXHo54EFKx5GMXzhh12Ib' : 'gTe626LcEBIe3tdrf17e7MANfmB6V8ix',
      domain: 'dev.login.sanomautbildning.se',
      useRefreshTokens: true,
      authorizationParams: {
        audience: 'https://sesame.sanomalearning.com',
        redirect_uri: window.location.origin
      }
    }
  },
  disablePrivileges: true,
  datadog: null,
  features: {
    CS: {
      groupsModule: true
    },
    OSS: {
      groupsModule: true
    },
    users: {
      connections: [
        {name: SKOLON_CONNECTION_NAME, value: SKOLON_CONNECTION_VALUE},
        {name: SKOLON_TEST_CONNECTION_NAME, value: SKOLON_TEST_CONNECTION_VALUE},
        {name: UTBILDNING_CONNECTION_NAME, value: UTBILDNING_CONNECTION_VALUE},
      ],
      '3rdUserAccountCreation': true,
      showDetailedConfirmationInfoAfterOsUserEmailEdit: true
    }
  },
  organisationContext: {
    allowedRoles: ['schooladmin', 'teacher']
  },
  gradeLevel: {
    values: "Kindergarten_4, 1, 2, 3, 4, 5, 6, 7, 8, 9, Unknown"
  },
  maxBulkPasswordResetSize: 40,
  defaultLang: {
    code: 'sv_se',
    translationKey: 'language.swedish'
  },
  lang: ['en_en', 'sv_se'],
  availableDataMaster: [SupportedDataMaster.SKOLON]
};
