export class QueryBuilder {
  private queryParams: QueryPathParam[] = [];
  private pathVariables: QueryPathParam[] = [];
  private headers: RequestHeader[] = [];

  constructor(private path: string) {
    this.headers.push();
  }

  public static forPath(path: string): QueryBuilder {
    return new QueryBuilder(path);
  }

  withRequestParam(name: string, value: string | number): QueryBuilder {
    this.queryParams.push(new QueryPathParam(name, encodeURIComponent(value.toString().trim())));
    return this;
  }

  withPathVariableParam(name: string, value: string | number): QueryBuilder {
    this.pathVariables.push(new QueryPathParam(name, encodeURIComponent(value.toString().trim())));
    return this;
  }

  withRequestParamIfDefined(name: string, value: string | number): QueryBuilder {
    if (value) {
      return this.withRequestParam(name, value);
    }
    return this;
  }

  withPathVariable(name: string, value: string | number): QueryBuilder {
    if (value) {
      return this.withPathVariableParam(name, value);
    }
    return this;
  }

  withHeader(name: string, value: string): QueryBuilder {
    this.headers.push(new RequestHeader(name, value.toString().trim()));
    return this;
  }

  build(): HttpRequest {
    let url = `${this.path}`;

    this.pathVariables.forEach(pathVariable => url = url.replace(`{${pathVariable.name}}`, pathVariable.value));

    if (this.queryParams.length > 0) {
      url = url + '?';
      this.queryParams.forEach(pathParam => url = url + `${pathParam.name}=${pathParam.value}&`);
    }

    return new HttpRequest(url, this.headers);
  }
}

export class HttpRequest {
  constructor(
    public url: string,
    public headers: RequestHeader[]
  ) {
  }
}

class QueryPathParam {
  public name: string;
  public value: string;

  constructor(
    name: string,
    value: string
  ) {
    this.name = name;
    this.value = this.transformValue(value);
  }

  transformValue(value: string): string {
    if (value.includes('|')) {
      return value.split('|').join('%7C');
    } else {
      return value;
    }
  }
}

export class RequestHeader {
  public name: string;
  public value: string;

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }

}

