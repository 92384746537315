import {PrivilegeType} from 'src/app/shared/models/privilege-type';
import {IconName} from "@fortawesome/free-solid-svg-icons";

export class NavigationItem {
  constructor(
    public id: string,
    public icon: IconName,
    public label: string,
    public path: string,
    public privilegeTypes: PrivilegeType[]
  ) {
  }
}
