import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {
  LibBaseComponent,
  OperationalOrganisation,
  TableInfiniteScrollService,
  TableScrollEvent
} from "@sesame/sesame-fe-library";
import {OperationalOrganisationElement} from "src/app/shared/module/organisation-detail/configure-platform-user-dialog/configure-platform-user-dialog-models";
import {OperationalOrganisationQuery} from "src/app/shared/service/operational-organisation-query";
import {Organisation} from "src/app/shared/models/organisation";

@Component({
  selector: 'app-select-linked-organisation-panel',
  templateUrl: './select-linked-organisation-panel.component.html',
  styleUrls: ['./select-linked-organisation-panel.component.scss']
})
export class SelectLinkedOrganisationPanelComponent extends LibBaseComponent implements OnInit {
  @Input()
  disabled = false;
  @Input()
  organisation?: Organisation;

  @Output()
  organisationsSelected = new EventEmitter<OperationalOrganisationElement[]>();

  readonly PAGE_SIZE = 10;
  searchForm: FormGroup;
  lastIndex = 0;
  organisations: OperationalOrganisationElement[] = [];
  selectedOrganisations: OperationalOrganisationElement[] = [];

  constructor(private readonly formBuilder: FormBuilder,
              private readonly operationalOrganisationQuery: OperationalOrganisationQuery) {
    super();
  }

  ngOnInit(): void {
    this.initializeSelectedOrganisations();
    this.createSearchForm();
    this.loadOrganisations(false);
    this.onEnterKeyClicked().subscribe(() => this.onSearch());
  }

  private createSearchForm(): void {
    this.searchForm = this.formBuilder.group({filter: [null, []]});
  }

  onOrganisationSelectionChange(element: OperationalOrganisationElement): void {
    if (element.selected) {
      this.addSelectedOrganisation(element);
    } else {
      this.removeSelectedOrganisation(element);
    }

  }

  private addSelectedOrganisation(element: OperationalOrganisationElement) {
    const selectedOrganisation = this.selectedOrganisations.find(selectedOrganisation => selectedOrganisation.id === element.id);
    if (!selectedOrganisation) {
      this.selectedOrganisations = this.selectedOrganisations.concat(element);
    }
    this.organisationsSelected.emit(this.selectedOrganisations);
  }

  removeSelectedOrganisation(element: OperationalOrganisationElement): void {
    this.selectedOrganisations = this.selectedOrganisations.filter(organisation => organisation.id !== element.id);
    const organisation = this.organisations.find(organisation => organisation.id === element.id);
    if (organisation) {
      organisation.selected = false;
    }
    this.organisationsSelected.emit(this.selectedOrganisations);
  }

  onScroll(scrollEvent: TableScrollEvent): void {
    const infiniteScrollService = new TableInfiniteScrollService(scrollEvent);
    const isEndOfList = this.organisations.length < this.PAGE_SIZE;

    if (infiniteScrollService.canFetchMoreData(isEndOfList)) {
      this.loadOrganisations(false);
    }
  }


  private loadOrganisations(resetLastIndex: boolean): void {
    if (resetLastIndex) {
      this.lastIndex = 0;
    }

    this.operationalOrganisationQuery.search(this.searchForm.controls.filter.value, this.PAGE_SIZE, this.lastIndex)
      .pipe(this.takeOne())
      .subscribe(response => {
        this.lastIndex = response.lastIndex;
        this.addNextOrganisations(response.organisations);
      });
  }


  private addNextOrganisations(organisations: OperationalOrganisation[]): void {
    const nextOrganisations = organisations.map(organisation => ({
      name: organisation.name,
      id: organisation.externalId,
      city: organisation.city,
      linkedOrganisation: organisation.linkedOrganisation,
      selected: !!this.selectedOrganisations.find(selectedOrganisation => selectedOrganisation.id === organisation.externalId),
      disabled: !!organisation.linkedOrganisation && !this.organisation.operationalOrganisations.find(operationalOrganisation => operationalOrganisation.externalId === organisation.externalId),
    }));
    this.organisations = this.organisations.concat(nextOrganisations);
  }

  private initializeSelectedOrganisations(): void {
    this.selectedOrganisations = this.organisation.operationalOrganisations.map(organisation => ({
      name: organisation.name,
      id: organisation.externalId,
      city: organisation.city,
      linkedOrganisation: organisation.linkedOrganisation,
      selected: true,
      disabled: false,
    }));
    this.organisationsSelected.emit(this.selectedOrganisations);
  }

  onSearch(): void {
    this.organisations = [];
    this.loadOrganisations(true);
  }
}
