import {NativeDateAdapter} from '@angular/material/core';
import {DateTime} from 'luxon';
import {Injectable} from '@angular/core';
import {AppVariables} from 'src/app/shared/util/app-variables';

const DATE_FORMAT = AppVariables.dateFormat;

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date): string {
    return DateTime.fromJSDate(date).toFormat(DATE_FORMAT);
  }

  parse(value: any): Date | null {
    return DateTime.fromFormat(value, DATE_FORMAT).toJSDate();
  }
}
