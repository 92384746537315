<h1 class="primary-color">
  {{'organisation.user.sms-data-import.title' | translate }}
</h1>
<mat-form-field appearance="outline" class="notranslate">
  <mat-label>{{'organisation.user.sms-data-import.status-search-label' | translate }}</mat-label>
  <mat-select [ngModel]="currentStatus" (ngModelChange)="onCurrentStatusChange($event)">
    <mat-option *ngFor="let status of allStatuses" [value]="status">
      {{'organisation.user.sms-data-import.status.' + status | translate}}
    </mat-option>
  </mat-select>
</mat-form-field>
<lib-table [config]="tableConfiguration"></lib-table>
