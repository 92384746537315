import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductsListPageComponent} from './pages/products-list-page/products-list-page.component';
import {ProductsRoutingModule} from './products-routing.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';

@NgModule({
  declarations: [ProductsListPageComponent],
  imports: [
    CommonModule,
    ProductsRoutingModule,
    MatFormFieldModule,
    MatTableModule,
    MatInputModule
  ]
})
export class ProductsModule {
}
