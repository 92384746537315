import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LibBaseComponent} from "@sesame/sesame-fe-library";
import {Organisation} from "src/app/shared/models/organisation";

@Component({
  selector: 'app-select-data-master-system-panel',
  templateUrl: './select-data-master-system-panel.component.html',
  styleUrls: ['./select-data-master-system-panel.component.scss']
})
export class SelectDataMasterSystemPanelComponent extends LibBaseComponent implements OnInit {
  readonly ALL_DATA_MASTER_SYSTEMS = [
    'smartschool',
    'wisa',
    'manual',
    'skolon'
  ];
  dataMasterSystemForm: FormGroup;

  @Input()
  disabled = false;
  @Input()
  organisation?: Organisation;

  @Output()
  dataMasterSelected = new EventEmitter<String>();

  constructor(private readonly formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.createDataMasterSystemForm();
  }

  private createDataMasterSystemForm(): void {
    const initialValue = this.disabled ? this.organisation?.dataMaster : null;

    this.dataMasterSystemForm = this.formBuilder.group({
      dataMasterSystem: [{value: initialValue, disabled: this.disabled}, [Validators.required]],
    });
    if (this.disabled && !this.organisation.dataMaster) {
      this.dataMasterSystemForm.get('dataMasterSystem').removeValidators(Validators.required);
    }
    this.dataMasterSystemForm.valueChanges
      .pipe(this.takeUntilDestroy())
      .subscribe(value => this.dataMasterSelected.emit(value.dataMasterSystem))
  }
}
