export interface SmsDataImportResponse {
  importMetadata: ImportMetadata[];
  pageSize: number;
  lastIndex: number;
}

export interface ImportMetadata {
  id: string;
  status: string;
  processedAt: Date;
  descriptions: string[];
  packageId: string;
}

export enum SmsImportStatus {
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED'
}