import {Component, Input} from '@angular/core';
import {LibBaseComponent} from "@sesame/sesame-fe-library";
import {Router} from "@angular/router";
import {environment} from "src/environments/environment";
import {Location} from '@angular/common';


@Component({
  selector: 'app-go-back-from-sms-data-import',
  templateUrl: './go-back-from-sms-data-import.component.html',
  styleUrls: ['./go-back-from-sms-data-import.component.scss']
})
export class GoBackFromSmsDataImportComponent extends LibBaseComponent {

  @Input()
  readonly organisationId: string;


  constructor(
    private readonly router: Router,
    private readonly location: Location
  ) {
    super();
  }

  goBack(): void {
    if (environment.isCustomerServiceContext) {
      this.router.navigate([`/organisations/${this.organisationId}`]);
    } else {
      this.location.back();
    }
  }

}
