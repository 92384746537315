import {Injectable} from '@angular/core';
import { Route, UrlSegment } from '@angular/router';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureGuard  {
  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return environment.features[environment.entryPoint]?.[route.data.requiredFeature];
  }
}
