import {Component, Input} from '@angular/core';
import {IconName} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-sidenav-menu-item',
  templateUrl: './sidenav-menu-item.component.html',
  styleUrls: ['./sidenav-menu-item.component.scss']
})
export class SidenavMenuItemComponent {
  @Input()
  readonly icon: IconName;
  @Input()
  readonly label: string;
  @Input()
  readonly path?: string;

  constructor() {
  }

}
