import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Injectable, Injector} from "@angular/core";
import {Observable, of, switchMap} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "src/environments/environment";
import {LibOrganisationContextService} from "@sesame/sesame-fe-library";
import {OrganisationQuery} from "src/app/shared/service/organisation-query";

@Injectable({
  providedIn: 'root'
})
export class IsPlatformUserGuard {

  constructor(private readonly injector: Injector,
              private readonly router: Router,
              private readonly organisationQuery: OrganisationQuery) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    if (environment.isCustomerServiceContext) {
      return of(true);
    } else {
      return this.injector.get(LibOrganisationContextService).getCurrent()
        .pipe(
          switchMap(myOrganisation => myOrganisation ? this.organisationQuery.findOne(myOrganisation.organisationId) : of(null)),
          map(organisation => {
            if (!organisation?.isPlatformUser) {
              return this.router.createUrlTree(['/']);
            } else {
              return organisation?.isPlatformUser;
            }
          }));
    }
  }

}