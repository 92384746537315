<div class="main-container">
  <app-main-toolbar (sidenavToggle)="sidenavPanel.toggle()">
    <ng-content additional-elements select="[additional-toolbar-elements]"></ng-content>
  </app-main-toolbar>

  <app-main-sidevan-panel #sidenavPanel [menuItems]="menuItems">
    <div class="content">
      <ng-content select="[organisation-detail-info]"></ng-content>
      <router-outlet></router-outlet>
    </div>
  </app-main-sidevan-panel>
</div>
