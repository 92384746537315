import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ENGLISH, Language, LibLanguageService} from '@sesame/sesame-fe-library';
import {Injectable} from '@angular/core';

@Injectable()
export class CsLanguageService implements LibLanguageService {
  private readonly allSupported = [
    ENGLISH
  ];
  private readonly currentLanguage: Subject<Language> = new BehaviorSubject<Language>(this.allSupported[0]);
  private readonly languages: Subject<Language[]> = new BehaviorSubject<Language[]>(this.allSupported);

  public getCurrent(): Observable<Language> {
    return this.currentLanguage;
  }

  public getAll(): Observable<Language[]> {
    return this.languages;
  }

  public change(language: Language): void {
    throw new Error('Not supported method');
  }
}
