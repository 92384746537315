<h5 class="subtitle">
  {{ 'organisation.user.change-organisation-dialog.subtitle' | translate: {organisationName: payload.organisation.name} }}
</h5>

<lib-inline-message [style]="InlineMessageStyle.WARN">
  <img src="assets/img/warning-message-icon.svg">
  <span>{{ 'organisation.user.delete-organisation-data-dialog.delete-warning' | translate }}</span>
</lib-inline-message>

<div class="note">{{ 'organisation.user.delete-organisation-data-dialog.note' | translate }}</div>
<div class="question">{{ 'organisation.user.delete-organisation-data-dialog.question' | translate }}</div>

<section class="actions d-flex justify-content-end mb-3 gap-3">
  <button class="button" (click)="abort()" color="primary" mat-stroked-button id="cancel-button">
    {{ 'button.cancel' | translate }}
  </button>

  <section>
    <lib-save-button
            (onClick)="onDeleteData()"
            [loading]="deleteDataButtonLoading"
            [text]="'organisation.user.delete-organisation-data-dialog.delete-button' | translate">
    </lib-save-button>
  </section>
</section>

