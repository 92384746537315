import {FormGroup} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {isEqual} from "lodash";

export function trimValueInField(form: FormGroup, fieldName: string): void {
  const field = form.get(fieldName);
  field.setValue(field.value?.trim() ?? field.value, {emitEvent: false});
}

export class FormUtils {

  initialFormValue: any;
  saveDisabled$: Observable<boolean>;

  constructor(public form: FormGroup) {}

  init() {
    this.initialFormValue = this.form.value;

    this.saveDisabled$ = this.form.valueChanges.pipe(
      map(value => (isEqual(value, this.initialFormValue) || this.form.invalid)),
      startWith(true)
    );
  }
}