import {NgModule, Provider} from '@angular/core';
import {environment} from 'src/environments/environment';
import {TranslateModule} from '@ngx-translate/core';
import {RoleLocationPipe} from 'src/app/shared/pipes/role-location.pipe';

const providers = (): Provider[] => {
  if (environment.isCustomerServiceContext) {
    return [];
  } else {
    return [];
  }
};

@NgModule({
  declarations: [RoleLocationPipe],
  exports: [
    RoleLocationPipe
  ],
  imports: [
    TranslateModule
  ],
  providers: [
    providers()
  ]
})
export class SharedPipesModule {
}
