import {TranslateLoader} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import en_en from 'src/assets/i18n/en_en.json';
import fr_be from 'src/assets/i18n/fr_be.json';
import nl_be from 'src/assets/i18n/nl_be.json';
import sv_se from 'src/assets/i18n/sv_se.json';
import {DUTCH, ENGLISH, FRENCH, SWEDISH} from "@sesame/sesame-fe-library";

export class MockTranslateLoader extends TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case ENGLISH.code: {
        return of(en_en);
      }
      case FRENCH.code: {
        return of(fr_be);
      }
      case DUTCH.code: {
        return of(nl_be);
      }
      case SWEDISH.code: {
        return of(sv_se);
      }
      default: {
        return of(en_en);
      }
    }
  }
}
