import {Component, Inject} from '@angular/core';
import {DialogV2BaseComponent} from "@sesame/sesame-fe-library";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Organisation} from "src/app/shared/models/organisation";

export interface DeactivateOrganisationDialogData {
  organisation: Organisation,
}

@Component({
  selector: 'app-deactivate-organisation-dialog',
  templateUrl: './deactivate-organisation-dialog.component.html',
  styleUrls: ['./deactivate-organisation-dialog.component.scss']
})
export class DeactivateOrganisationDialogComponent extends DialogV2BaseComponent<DeactivateOrganisationDialogData, void> {

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: DeactivateOrganisationDialogData,
  ) {
    super(null, data);
  }

  getResult(): void {
  }

}
