import {Component} from '@angular/core';
import {LibBaseComponent} from '@sesame/sesame-fe-library';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent extends LibBaseComponent {

  constructor() {
    super();
  }
}
