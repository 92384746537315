<app-secured-content-page>
  <span class="additional-toolbar-elements" additional-toolbar-elements>
    <ng-container *ngIf="allOrganisations | async as organisations">
      <div *ngIf="organisations.length === 1" class="d-flex align-items-center font-14 mr-3">
        {{organisations[0].name}}
      </div>

      <mat-form-field *ngIf="organisations.length > 1" appearance="fill" class="notranslate">
        <mat-select [ngModel]="currentOrganisationContext | async"
                    (valueChange)="changeCurrentOrganisationContext($event)">
          <mat-option *ngFor="let organisation of organisations"
                      [value]="organisation">{{organisation.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <mat-form-field appearance="fill" *ngIf="allLanguages | async as languages" class="notranslate">
      <mat-select [ngModel]="currentLanguage | async"
                  (valueChange)="changeCurrentLanguage($event)" [compareWith]="compareLanguagesByCode">
        <mat-option *ngFor="let language of languages"
                    [value]="language">{{language.translationKey | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </span>

  <ng-container organisation-detail-info *ngIf="organisation | async as organisation">
    <app-go-back-from-sms-data-import *ngIf="isSmsDataImportVisible" [organisationId]="organisation.organisationId">
    </app-go-back-from-sms-data-import>
  </ng-container>
</app-secured-content-page>
