import {Component, OnInit} from '@angular/core';
import {
  ENGLISH,
  LibBaseComponent,
  LibLanguageService,
  LibOrganisationContextService,
  SnackBarService
} from '@sesame/sesame-fe-library';
import {AuthService} from '@auth0/auth0-angular';
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {environment} from "src/environments/environment";
import {combineLatestWith, EMPTY} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {Language} from "@sesame/sesame-fe-library/lib/core/lib-language.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent extends LibBaseComponent implements OnInit {

  private language: Language = ENGLISH;
  protected readonly isCustomerServiceContext = environment.isCustomerServiceContext;

  constructor(
    private readonly languageService: LibLanguageService,
    private readonly authService: AuthService,
    private readonly router: Router,
    readonly organisationContext: LibOrganisationContextService,
    readonly snackbar: SnackBarService,
    readonly translate: TranslateService
  ) {
    super();
    this.handleRoleBasedLogic();
  }

  ngOnInit(): void {
    this.languageService.getCurrent()
      .pipe(this.takeUntilDestroy())
      .subscribe(lang => this.language = lang)
  }

  onLoginClick(): void {
    this.authService.loginWithRedirect({
      authorizationParams: {language: this.language.code}
    });
  }

  handleRoleBasedLogic() {
    const isTeacherRole = environment.organisationContext.allowedRoles.includes('teacher');
    const isAuthenticated$ = this.authService.isAuthenticated$.pipe(this.takeUntilDestroy());

    if (!isTeacherRole) {
      this.handleNonTeacherLogic(isAuthenticated$);
    } else {
      this.handleTeacherLogic(isAuthenticated$);
    }
  }

  private handleNonTeacherLogic(isAuthenticated$) {
    isAuthenticated$
      .pipe(combineLatestWith(this.organisationContext.getCurrent()))
      .subscribe(([isAuthenticated, currentOrganisation]) => {
        if (isAuthenticated) {
          this.handleAuthenticatedNonTeacher(currentOrganisation);
        } else {
          this.tryToGetAccessTokenSilently();
        }
      });
  }

  private handleAuthenticatedNonTeacher(currentOrganisation) {
    if (!environment.isCustomerServiceContext && currentOrganisation == null) {
      this.snackbar.showWarning(
        this.translate.instant('error.not-found.administrationRole'),
        SnackBarService.FOREVER_DURATION
      );
    } else {
      this.redirect();
    }
  }

  private handleTeacherLogic(isAuthenticated$) {
    isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.redirect();
      } else {
        this.tryToGetAccessTokenSilently();
      }
    });
  }


  redirect(): void {
    if (this.router.url === '/') {
      if (environment.isCustomerServiceContext) {
        this.router.navigate(['app']);
      } else {
        this.router.navigate(['licenses']);
      }
    }
  }

  private tryToGetAccessTokenSilently() {
    (this.authService as any).auth0Client.options.useRefreshTokens = false;
    this.authService.getAccessTokenSilently()
      .pipe(catchError(() => EMPTY))
      .subscribe(() => {
        (this.authService as any).auth0Client.options.useRefreshTokens = true;
      })
  }
}
