import {Injectable} from "@angular/core";
import {OsmHttpClientService} from '@sesame/sesame-fe-library';
import {Observable} from "rxjs";
import {QueryBuilder} from "src/app/shared/util/query-builder";

@Injectable({
  providedIn: 'root'
})
export class UpdatePlatformUserCommand {

  constructor(private readonly http: OsmHttpClientService) {
  }

  updatePlatformUser(organisationId: string, isPlatformUser: boolean): Observable<void> {
    const url = QueryBuilder.forPath('organisations/{organisationId}/platform-user')
      .withPathVariable('organisationId', organisationId)
      .withRequestParam('isPlatformUser', String(isPlatformUser))
      .build();

    return this.http.put(url, {});
  }

}