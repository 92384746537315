<form [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>{{'organisation.integration.client-id' | translate}}</mat-label>
    <input formControlName="clientId"
           id="client-id"
           matInput
           type="text"
           placeholder="{{'organisation.integration.placeholder' | translate }}"
    >
    <mat-error *ngIf="form.get('clientId')?.errors?.required">
      {{'organisation.integration.client-id-required-error' | translate}}
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{'organisation.integration.client-secret' | translate}}</mat-label>
    <input formControlName="clientSecret"
           id="client-secret"
           matInput
           type="password"
           placeholder="{{'organisation.integration.placeholder' | translate }}"
    >
    <mat-error *ngIf="form.get('clientSecret')?.errors?.required">
      {{'organisation.integration.client-secret-required-error' | translate}}
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{'organisation.integration.web-access-url' | translate}}</mat-label>
    <input formControlName="accessUrl"
           id="access-url"
           matInput
           type="url"
           placeholder="{{'organisation.integration.placeholder' | translate }}"
    >
    <mat-error *ngIf="form.get('accessUrl')?.errors?.required">
      {{'organisation.integration.web-access-url-required-error' | translate}}
    </mat-error>
    <mat-error *ngIf="form.get('accessUrl')?.errors?.pattern">
      {{'organisation.integration.web-access-url-pattern-error' | translate}}
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{'organisation.integration.token-endpoint' | translate}}</mat-label>
    <input formControlName="tokenUrl"
           id="token-url"
           matInput
           type="url"
           placeholder="{{'organisation.integration.placeholder' | translate }}"
    >
    <mat-error *ngIf="form.get('tokenUrl')?.errors?.required">
      {{'organisation.integration.token-endpoint-required-error' | translate}}
    </mat-error>
    <mat-error *ngIf="form.get('tokenUrl')?.errors?.pattern">
      {{'organisation.integration.token-endpoint-pattern-error' | translate}}
    </mat-error>
  </mat-form-field>
</form>