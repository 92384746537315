import {Injectable} from '@angular/core';
import {MainSidevanPanelComponent} from 'src/app/core/components/main-sidevan-panel/main-sidevan-panel.component';

@Injectable({
  providedIn: 'root'
})
export class MainSidevanPanelService {
  private sidenavPanel: MainSidevanPanelComponent;

  constructor() {
  }

  registerSidenavPanel(sidenavPanel: MainSidevanPanelComponent): void {
    this.sidenavPanel = sidenavPanel;
  }

  open(): void {
    this.sidenavPanel?.toggle(true);
  }

  close(): void {
    this.sidenavPanel?.toggle(false);
  }
}
