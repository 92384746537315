import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogBaseComponent, DialogService, OperationalOrganisation} from "@sesame/sesame-fe-library";
import {OperationalOrganisationService} from "src/app/shared/service/operational-organisation.service";
import {
  ConfigurePlatformUserDialogData,
  OperationalOrganisationElement
} from "src/app/shared/module/organisation-detail/configure-platform-user-dialog/configure-platform-user-dialog-models";


@Component({
  selector: 'app-configure-platform-user-dialog',
  templateUrl: './configure-platform-user-dialog.component.html',
  styleUrls: ['./configure-platform-user-dialog.component.scss']
})
export class ConfigurePlatformUserDialogComponent extends DialogBaseComponent<ConfigurePlatformUserDialogData, void> implements OnDestroy {
  readonly PAGE_SIZE = 10;

  organisations: OperationalOrganisationElement[] = [];
  selectedOrganisations: OperationalOrganisationElement[] = [];
  selectedDataMasterSystem?: string;

  firstStepIndex: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ConfigurePlatformUserDialogData,
    private dialogRef: MatDialogRef<ConfigurePlatformUserDialogComponent>,
    private readonly updateOperationalOrganisationCommand: OperationalOrganisationService,
    dialogService: DialogService,
  ) {
    super(dialogService, data);
    this.setFirstStepIndex();
    this.selectedDataMasterSystem = this.payload.organisation?.dataMaster;
  }


  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  close(): void {
    this.dialogRef.close();
  }

  submit(): void {
    const selectedOperationalOrganisationIds = this.selectedOrganisations.map(organisation => organisation.id);
    this.updateOperationalOrganisationCommand.updateOperationalOrganisation(
      this.payload.organisation.organisationId,
      selectedOperationalOrganisationIds,
      this.selectedDataMasterSystem,
    )
      .pipe(this.takeUntilDestroy())
      .subscribe(() => this.dialogRef.close({
          organisationId: this.payload.organisation.organisationId,
          operationalOrganisations: this.selectedOrganisations.map(organisation =>
            new OperationalOrganisation(organisation.name, organisation.id, organisation.city, organisation.linkedOrganisation)
          )
        })
      );
  }


  private setFirstStepIndex(): void {
    if (this.payload.editMode) {
      this.firstStepIndex = 1;
    } else {
      this.firstStepIndex = 0;
    }
  }

  getResult(): void {
  }
}
