<form *ngIf="searchForm" (keydown.enter)="onEnterKeySearch$.next()" [formGroup]="searchForm">
  <div class="d-flex flex-wrap align-items-baseline justify-content-between w-100 mt-2">
    <mat-form-field appearance="outline" class="w-100 pb-0">
      <mat-label>
        <fa-icon [icon]="['far', 'magnifying-glass']" size="lg"></fa-icon>
        {{ 'organisation.user.configure-platform-user-dialog.step-2.search-placeholder' | translate }}
      </mat-label>
      <input formControlName="filter" id="filter" matInput>
      <button (click)="searchForm.controls.filter.reset(); onSearch()"
              *ngIf="searchForm.controls.filter.value"
              mat-icon-button matSuffix
              type="button">
        <fa-icon [icon]="['far', 'xmark']" size="lg"></fa-icon>
      </button>
    </mat-form-field>
  </div>
</form>
<section>
  <mat-chip-listbox>
    <ng-container *ngFor="let organisation of selectedOrganisations">
      <mat-chip-option (removed)="removeSelectedOrganisation(organisation)">
        {{ organisation.name }}
        <button matChipRemove>
          <fa-icon [icon]="['far', 'cancel']" size="lg"></fa-icon>
        </button>
      </mat-chip-option>
    </ng-container>
  </mat-chip-listbox>
</section>
<ng-container *ngIf="organisations.length > 0">
  <h5 class="step-title mt-3">{{ 'organisation.user.configure-platform-user-dialog.step-2.linked-organisations-title' | translate }}</h5>
  <lib-paginated-list [listItems]="organisations"
                      [multiple]="true"
                      (onChange)="onOrganisationSelectionChange($event)"
                      (onScroll)="onScroll($event)">
    <ng-container header>
      <section class="list-container d-flex w-100">
        <section class="item-container">
          <div class="item item-column w-25">{{ 'organisation.user.configure-platform-user-dialog.step-2.organisation-name' | translate }}</div>
          <div class="item item-column w-25">{{ 'organisation.user.configure-platform-user-dialog.step-2.organisation-id' | translate }}</div>
          <div class="item item-column w-25">{{ 'organisation.user.configure-platform-user-dialog.step-2.organisation-city' | translate }}</div>
          <div class="item item-column w-25">{{ 'organisation.user.configure-platform-user-dialog.step-2.linked-organisation' | translate }}</div>
        </section>
      </section>
    </ng-container>
    <ng-template #itemTemplate let-item="item">
      <section class="list-container p-2 d-flex">
        <section class="item-container">
          <div class="item text-wrap w-25">{{ item.name }}</div>
          <div class="item text-wrap w-25">{{ item.id }}</div>
          <div class="item text-wrap w-25">{{ item.city }}</div>
          <div class="item text-wrap w-25">{{ item.linkedOrganisation?.name ?? '' }}</div>
        </section>
      </section>
    </ng-template>
  </lib-paginated-list>
</ng-container>
<ng-container *ngIf="organisations.length === 0">
  <section class="no-results">
    <section class="d-flex w-100 justify-content-center align-items-center">
      <section class="d-flex flex-column align-items-center">
        <fa-icon class="search-icon" [icon]="['far', 'magnifying-glass']" size="lg"></fa-icon>
        <h3>{{ 'organisation.user.configure-platform-user-dialog.step-2.error1' | translate }}</h3>
        <h4 class="subtitle">{{ 'organisation.user.configure-platform-user-dialog.step-2.error2' | translate }}</h4>
      </section>
    </section>
  </section>
</ng-container>