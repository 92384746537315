import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConditionalLabelComponent } from 'src/app/shared/module/common-components/components/label-with-default/conditional-label.component';
import {IconModule} from "src/app/shared/module/icon/icon.module";

@NgModule({
  declarations: [ConditionalLabelComponent],
  exports: [
    ConditionalLabelComponent,
  ],
  imports: [
    CommonModule,
    IconModule
  ]
})
export class CommonComponentsModule { }
