import {Injectable} from "@angular/core";
import {OsmHttpClientService} from "@sesame/sesame-fe-library";
import {Observable} from "rxjs";
import {SmsDataImportResponse} from "src/app/shared/models/sms-data-import";
import {QueryBuilder} from "src/app/shared/util/query-builder";

@Injectable({
  providedIn: 'root'
})
export class SmsImportQuery {

  constructor(private readonly http: OsmHttpClientService) {
  }

  findAll(status: string, lastIndex: number, pageSize: number, organisationId: string): Observable<SmsDataImportResponse> {
    const query = QueryBuilder.forPath(`osdi/${organisationId}/import-metadata`)
      .withRequestParamIfDefined('lastIndex', lastIndex)
      .withRequestParamIfDefined('pageSize', pageSize)
      .withRequestParam('status', status)
      .withHeader('X-Applies-To-OrganisationId', organisationId)
      .build();

    return this.http.get<SmsDataImportResponse>(query);
  }

}