export enum PrivilegeType {
  VIEW_LICENSES = 'VIEW_LICENSES',
  MANAGE_LICENSES = 'MANAGE_LICENSES',
  DELETE_LICENSES = 'DELETE_LICENSES',
  VIEW_ASSIGNEES = 'VIEW_ASSIGNEES',
  MANAGE_ASSIGNEES = 'MANAGE_ASSIGNEES',
  VIEW_ASSIGNMENT_CODES = 'VIEW_ASSIGNMENT_CODES',
  MANAGE_ASSIGNMENT_CODES = 'MANAGE_ASSIGNMENT_CODES',
  DELETE_ACTIVATION_CODES = 'DELETE_ACTIVATION_CODES',
  VIEW_LICENSE_CODES = 'VIEW_LICENSE_CODES',
  MANAGE_LICENSE_CODES = 'MANAGE_LICENSE_CODES',
  VIEW_PRODUCTS = 'VIEW_PRODUCTS',
  ACTIVATE_ACTIVATION_CODES = 'ACTIVATE_ACTIVATION_CODES',
  VIEW_ORGANISATIONS = 'VIEW_ORGANISATIONS',
  REMOVE_SCHOOLADMIN_ROLE = 'REMOVE_SCHOOLADMIN_ROLE',
  ADD_MY_SCHOOLADMIN_ROLE = 'ADD_MY_SCHOOLADMIN_ROLE',
  VIEW_USERS = 'VIEW_USERS',
  RESET_USER_PASSWORD = 'RESET_USER_PASSWORD',
  MANAGE_USERS = 'MANAGE_USERS',
  END_CAREER = 'END_CAREER',
  LINK_USERS = 'LINK_USERS',
  MANAGE_USER_ACCOUNT = 'MANAGE_USER_ACCOUNT',
  DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT',
  MANAGE_MY_ACCOUNT = 'MANAGE_MY_ACCOUNT',
  MANAGE_MY_IDENTITIES = 'MANAGE_MY_IDENTITIES',
  LINK_USER_ACCOUNT = 'LINK_USER_ACCOUNT',
  VIEW_DELIVERY_RESULTS = 'VIEW_DELIVERY_RESULTS',
  VIEW_USER_ASSIGNMENTS = 'VIEW_USER_ASSIGNMENTS',
  VIEW_GROUPS = 'VIEW_GROUPS',
  MANAGE_GROUPS = 'MANAGE_GROUPS',
  DELETE_GROUPS = 'DELETE_GROUPS',
  MANAGE_GROUP_MEMBERS = 'MANAGE_GROUP_MEMBERS',
  VIEW_GROUP_MEMBERS = 'VIEW_GROUP_MEMBERS',
  VIEW_ORGANISATION_MEMBERS = 'VIEW_ORGANISATION_MEMBERS',
  MANAGE_ORGANISATION_MEMBERS = 'MANAGE_ORGANISATION_MEMBERS',
  MANAGE_MY_ORGANISATION_MEMBERSHIP = 'MANAGE_MY_ORGANISATION_MEMBERSHIP',
  GENERATE_LINK_CODE = 'GENERATE_LINK_CODE',
  VIEW_LINK_CODE = 'VIEW_LINK_CODE',
  MANAGE_ORGANISATION = 'MANAGE_ORGANISATION',
  MANAGE_ORGANISATION_CLIENT = 'MANAGE_ORGANISATION_CLIENT',
  BULK_RESET_USER_PASSWORD = 'BULK_RESET_USER_PASSWORD',
  UPLOAD_OS_DATA = 'UPLOAD_OS_DATA',
}
