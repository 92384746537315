import {Injectable} from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public static readonly FIRST_PAGE_URL_REGEXP = new RegExp('([^\\/]+)\/*.*');

  constructor(private readonly router: Router) {
  }

  navigateToRootPath(): void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/empty', {skipLocationChange: true}).then(() => {
      let urlToNavigate = currentUrl.replace(NavigationService.FIRST_PAGE_URL_REGEXP, '$1');
      this.router.navigate([urlToNavigate])
        .then(value => {
          if (!(value && urlToNavigate === this.router.url)) {
            // destination route not exists, redirect into root
            this.router.navigate([''])
          }
        })
    });
  }
}
