import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {OperationalOrganisationsResponse} from "src/app/shared/models/organisation";
import {OsmHttpClientService} from '@sesame/sesame-fe-library';
import {QueryBuilder} from "src/app/shared/util/query-builder";

@Injectable({
  providedIn: 'root'
})
export class OperationalOrganisationQuery {

  constructor(private readonly http: OsmHttpClientService) {
  }

  search(query?: string, pageSize?: number, lastIndex?: number): Observable<OperationalOrganisationsResponse> {
    const url = QueryBuilder.forPath('operational-organisations')
      .withRequestParam('query', query ?? '')
      .withRequestParam('pageSize', pageSize)
      .withRequestParam('lastIndex', lastIndex)
      .build();

    return this.http.get<OperationalOrganisationsResponse>(url);
  }

}