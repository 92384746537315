import {Product} from 'src/app/shared/models/product';

export class ProductsListCriteria {
  constructor(
    public productId: string,
    public productName: string,
    public productEan: string,
    public lastIndex: number
  ) {
  }

  static empty(): ProductsListCriteria {
    return new ProductsListCriteria(null, null, null, null);
  }
}

export interface ProductsList {
  products: Product[];
  pageSize: number;
  lastIndex: number;
}


export class ProductListFilter {
  constructor(
    public productId: string,
    public productName: string,
    public productEan: string
  ) {
  }
}
